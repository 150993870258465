import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicRolePermissionMapping } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { CBox_ActionCreateAgentData, CBox_ActionCreateAgentResponse } from "@server/services/cbox/public/api/v1/resources/agent/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBox_ActionCreateUserData, CBox_GetSelfUserResponse } from "@server/services/cbox/public/api/v1/resources/user/types";
import { FormattedPermission } from "app/profile/agents/types";
import { Message } from "primeng/api";
import { AutoFocusModule } from "primeng/autofocus";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { ProfileService } from "src/services/profile/profile.service";
import { ToastService } from "src/services/toast/toast.service";
import { UserService } from "src/services/user/user.service";

@Component({
  selector: "app-cbox-profile-platform-user-create",
  templateUrl: "cbox-profile-platform-user-create.component.html",
  styleUrls: ["./cbox-profile-platform-user-create.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DividerModule,
    InputGroupModule,
    InputGroupAddonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AutoFocusModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    MessagesModule
  ]
})

export class CBoxProfilePlatformUserCreateComponent implements OnInit {

  isCreating = signal(false);
  userCreateStatus = signal<"success" | "error" | "unknown">("unknown");
  agentCreateStatus = signal<"success" | "error" | "unknown">("unknown");
  userForm: FormGroup;
  userData = signal<CBox_GetSelfUserResponse | undefined>(undefined);
  permissions = signal<FormattedPermission[] | undefined>(undefined);
  roles = signal<CBox_PublicRolePermissionMapping | undefined>(undefined);
  zones = signal<Record<number, { id: string; name: string }>[]>([]);
  zonesFetched = signal(false);

  userSaveRetryAllowed = signal(true);
  agentSaveRetryAllowed = signal(true);

  autoGeneratedIdentifierMessage = signal<Message[]>([{
    severity: "info",
    detail:
        `Identificatorul agentului va fi generat automat de sistemul nostru.
        Acest cod va fi folosit în sistem pentru afișarea informațiilor
        legate de acest agent. În cazul în care doriți să atribuiți un
        identificator personalizat, vă rugăm să bifați căsuța din formular.`
  }]);

  get lockersForm(): FormGroup {
    return this.userForm.get("lockers") as FormGroup;
  }

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private userService: UserService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfilePlatformUserCreateComponent>,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.userForm = this.fb.group({
        name: ["", Validators.required],
        email: ["", Validators.required],
        phone: ["", Validators.required],
        notes: [""],
        zoneId: [undefined, Validators.required],
        role: ["", Validators.required],
        identifier: [{
          value: "",
          disabled: true
        }, Validators.required],
        customIdentifier: [false],
        shouldCreateAgent: [true]
      })
    }

  ngOnInit(): void {
    this.init();
  }

  loadData(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchZones();
      this.fetchUserData();
    }
  }

  toggleAllLockers(): void {
    const f = this.userForm;
    if (f.get("lockers.all")?.value) {
      f.get("lockers.specific")?.disable();
      f.get("lockers.specific")?.patchValue([], {
        emitEvent: false
      })
    } else {
      f.get("lockers.specific")?.enable();
    }
  }

  toggleCustomIdentifier(): void {
    const f = this.userForm;
    const identifier = f.get("identifier");
    if (f.get("customIdentifier")?.value) {
      identifier?.enable();
    } else {
      identifier?.disable();
    }
  }

  noPhoneNumberToggled(): void {
    const f = this.userForm;
    const phoneNumber = f.get("phone");
    if (f.get("noPhoneNumber")?.value) {
      phoneNumber?.disable();
      phoneNumber?.reset();
    } else {
      phoneNumber?.enable();
    }
  }

  noEmailAddressToggled(): void {
    const f = this.userForm;
    const email = f.get("email");
    if (f.get("noEmailAddress")?.value) {
      email?.disable();
      email?.reset();
    } else {
      email?.enable();
    }
  }

  saveUser(): void {
    this.isCreating.set(true);
    this.api.post("backend/users", this.getUserCreateStructure()).subscribe(() => {
      this.userCreateStatus.set("success");
      if (!!this.userForm.get("shouldCreateAgent")?.value) {
        this.createAgent();
      } else {
        this.dialogRef.close();
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost creat cu succes!");
      }
    }, (e: HttpErrorResponse) => {
      this.userCreateStatus.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  retryUserSave(): void {
    this.userSaveRetryAllowed.set(false);
    this.userCreateStatus.set("unknown");
    this.saveUser();
  }

  retryAgentSave(): void {
    this.agentSaveRetryAllowed.set(false);
    this.agentCreateStatus.set("unknown");
    this.createAgent();
  }

  private async init(): Promise<void> {
    const roles = await this.config.getRolePermissionMapping()
    this.roles.set(this.filterRoles(roles));
    this.loadData();
  }

  private fetchZones(): void {
    this.api.get<CBox_PublicSuccessResponse<(CBox_GetLockerShortData & {
      zones: {
        id: string;
        name: string;
      }[]
    })[]>>("backend/locker/identifiers?includeZones=true").subscribe((response) => {
      const zones: {id: string; name: string}[] = [];
      for (const locker of response.data) {
        for (const zone of locker.zones) {
          if (!zones.find(z => z.id === zone.id)) {
            zones.push({id: zone.id, name: zone.name});
          }
        }
      }
      this.zones.set(zones);
      this.zonesFetched.set(true);
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }

  private createAgent(): void {
    const agentPayload = this.getAgentCreateStructure();
    this.api.post<CBox_PublicSuccessResponse<CBox_ActionCreateAgentResponse>>("backend/agents", agentPayload).subscribe((response) => {
      this.agentCreateStatus.set("success");
    }, (e: HttpErrorResponse) => {
      this.agentCreateStatus.set("error");
      handlePublicApiError(e, this.toastService);
    });
  }

  private fetchUserData(): void {
    this.userService.getData();
  }

  private filterRoles(roles: CBox_PublicRolePermissionMapping): CBox_PublicRolePermissionMapping {
    return roles.filter((role) => role.canCreate);
  }

  private getUserCreateStructure(): CBox_ActionCreateUserData["body"] {
    const f = this.userForm.value;
    const data: CBox_ActionCreateUserData["body"] = {
      name: f.name,
      email: f.email,
      phone: f.phone,
      role: f.role,
      zoneId: f.zoneId,
      identifier: f.identifier,
      notes: f.notes,
    };

    return data;
  }

  private getAgentCreateStructure(): CBox_ActionCreateAgentData["body"] {
    const data: CBox_ActionCreateAgentData["body"] = {
      agent: {
        name: this.userForm.get("name")?.value,
        phone: this.userForm.get("phone")?.value,
        email: this.userForm.get("email")?.value,
        identifier: this.userForm.get("identifier")?.value,
        zoneId: this.userForm.get("zoneId")?.value,
      },
      permittedLockers: {
        all: true
      }
    }

    return data;
  }
}