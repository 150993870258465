<form [formGroup]="filtersForm" class="grid align-items-end row-gap-1 mt-2">
  <div class="col-12 lg:col-4 flex-order-1 lg:flex-order-0">
    <span class="font-bold block mb-2">Interval căutare</span>
    <p-calendar
      styleClass="w-full"
      dateFormat="dd/mm/yy"
      selectionMode="range"
      formControlName="interval"
      [showIcon]="true"
      [showOnFocus]="true"
      [placeholder]="'Data de la'"
      [panelStyle]="{
        'height': '439px'
      }"
    ></p-calendar>
  </div>

  <div class="col-12 lg:col-4 flex-order-2 lg:flex-order-1">
    <span class="font-bold block mb-1">Lockere</span>
    <p-multiSelect
      formControlName="lockers"
      optionLabel="name"
      optionValue="id"
      placeholder="Filtrează lockere"
      styleClass="w-full"
      appendTo="body"
      filterBy="id,name"
      [options]="lockers()"
      [loading]="!lockers()"
    >
      <ng-template let-locker pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            <small class="text-gray-400">#{{ locker?.id }}</small>
            <p class="m-0">{{ locker?.name }}</p>
          </div>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>

  <div class="
    col-12 flex-order-0
    lg:flex-order-2 lg:col-4
    text-right
  ">
    <p-button
      label="Generează raport"
      icon="pi pi-file-plus"
      (onClick)="generateReport()"
    ></p-button>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm"
  currentPageReportTemplate="{first} - {last} din {totalRecords} rapoarte"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="data()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr pRowGroupHeader>
        <td colspan="8">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th></th>
      <th>Locker</th>
      <th class="text-center">Ridicate de clienți</th>
      <th class="text-center">Lăsate de clienți</th>
      <th class="text-center">Ridicate de curier</th>
      <th class="text-center">Lasate de curier</th>
      <th class="text-center">Recuperate de curier</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="frozenheader">
    @if (loading()) {
      <tr pRowGroupHeader>
        <td colspan="8">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th></th>
      <th>Locker</th>
      <th class="text-center">Ridicate de clienti</th>
      <th class="text-center">Lasate de clienti</th>
      <th class="text-center">Ridicate de curier</th>
      <th class="text-center">Lasate de curier</th>
      <th class="text-center">Recuperate de curier</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-group let-rowIndex="rowIndex">
    <!-- Group Header Row -->
    <tr>
      <td colspan="2" [ngClass]="{
        'opacity-40': group.items.length === 0
      }">
        <b>{{ group.date | date: 'dd MMMM yyyy' }}</b>
        @if (group.items.length === 0) {
          <span> - fară activitate</span>
        }
      </td>
      <td class="text-center" [ngClass]="{
        'opacity-40': group.items.length === 0
      }">
        <b>{{ totalsByDates()[group.date]["USER_PICK_UP"] }}</b>
      </td>
      <td class="text-center" [ngClass]="{
        'opacity-40': group.items.length === 0
      }">
        <b>{{ totalsByDates()[group.date]["USER_DROP_OFF"] }}</b>
      </td>
      <td class="text-center" [ngClass]="{
        'opacity-40': group.items.length === 0
      }">
        <b>{{ totalsByDates()[group.date]["COURIER_TAKE_ORDERS"] }}</b>
      </td>
      <td class="text-center" [ngClass]="{
        'opacity-40': group.items.length === 0
      }">
        <b>{{ totalsByDates()[group.date]["COURIER_PUT_ORDERS"] }}</b>
      </td>
      <td class="text-center" [ngClass]="{
        'opacity-40': group.items.length === 0
      }">
        <b>{{ totalsByDates()[group.date]["COURIER_RETRIEVE_EXPIRED"] }}</b>
      </td>
    </tr>

    <!-- Grouped Rows -->
    @for (report of group.items; track $index) {
      <ng-container>
        <tr>
          <td>
            @if (report?.locker) {
              <button
                size="small"
                type="button"
                pButton
                pRipple
                class="p-button-text p-button-rounded p-button-plain mr-2"
                [icon]="isRowExpanded(report) ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                (click)="toggleRowExpansion(report)"
              ></button>
            }
          </td>
          <td>
            @if (report?.locker) {
              @let locker = report.locker;
              <small class="text-gray-400">#{{ locker.identifier }}</small><br>
              <b>{{ locker.name }}</b>
              <br>
              <small>{{ lockerCleanAddress(locker.addressParts) }}</small>
            } @else {
              <b class="text-gray-400">-</b>
            }
          </td>
          <td class="text-center">{{ getActionCount('USER_PICK_UP', report.actions?.counts) }}</td>
          <td class="text-center">{{ getActionCount('USER_DROP_OFF', report.actions?.counts) }}</td>
          <td class="text-center">{{ getActionCount('COURIER_TAKE_ORDERS', report.actions?.counts) }}</td>
          <td class="text-center">{{ getActionCount('COURIER_PUT_ORDERS', report.actions?.counts) }}</td>
          <td class="text-center">{{ getActionCount('COURIER_RETRIEVE_EXPIRED', report.actions?.counts) }}</td>
        </tr>

        <!-- Expanded Row -->
        @if (isRowExpanded(report)) {
          <tr>
            <td colspan="8">
              <div class="p-3">
                <p-table
                  styleClass="p-datatable-sm"
                  [value]="report.actions.details"
                  dataKey="id"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Declanșat de</th>
                      <th>AWB</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-action>
                    <tr>
                      <td>
                        <div [ngClass]="{
                          'bg-green-400': action.type === 'USER_PICK_UP',
                          'bg-blue-400': action.type === 'USER_DROP_OFF',
                          'bg-indigo-400': action.type === 'COURIER_TAKE_ORDERS',
                          'bg-pink-400': action.type === 'COURIER_PUT_ORDERS',
                          'bg-orange-500': action.type === 'COURIER_RETRIEVE_EXPIRED',
                          'bg-red-500': action.type === 'UNDEFINED'
                        }" class="py-1 px-2 w-max border-round-xs font-bold text-white shadow-1">
                          <p class="text-xs m-0">
                            {{ actionTranslations()[action.type] }}
                          </p>
                        </div>
                      </td>
                      <td>{{ action.occurredAt | date: 'dd MMMM yyyy HH:mm' }}</td>
                      <td>
                        @switch (action.performedBy.type) {
                          @case ("AGENT") {
                            <p class="text-xs text-gray-400 m-0">{{ action.performedBy.identifier }}</p>
                            <b class="text-lg">{{ action.performedBy.name }}</b>
                            <p class="text-xs m-0">
                              {{ action.performedBy.phone }}
                              @if (action.performedBy.email) {
                                <span>
                                  &#x2022;
                                  {{ action.performedBy.email }}
                                </span>
                              }
                            </p>
                          }

                          @case ("USER") {
                            Utilizator
                          }
                        }
                      </td>
                      <td>{{ action.request.awb }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        }
      </ng-container>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage">
      @if (!loading()) {
      <tr>
        <td colspan="8">
          <p class="text-center text-gray-400 text-xl font-bold">Nu există rapoarte</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>