import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Inject,
  InjectionToken,
  Injector,
  OnInit,
  PLATFORM_ID,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { first } from 'rxjs';
import { handlePublicApiError } from 'src/services/api/api.service';
import { LockersService } from 'src/services/lockers/lockers.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { SessionService } from 'src/services/session/session.service';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import {
  CBox_AdminEquipmentListItem,
  CBox_AdminEquipmentListRequestData,
  CBox_EquipmentType,
} from '@server/services/cbox/public/api/v1/resources/internal/equipment/types';
import { CBoxAdminEquipmentCreateDialogComponent } from '../dialogs/create/cbox-admin-equipment-create.component';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { PaginatedTableWithFilters } from 'app/shared/paginated-table-with-filters';

@Component({
  selector: 'app-cbox-admin-equipments-list',
  templateUrl: './cbox-admin-equipments-list.component.html',
  styleUrls: ["./cbox-admin-equipments-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ProgressBarModule,
    MenuModule,
    CheckboxModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    IconFieldModule,
    InputIconModule
  ]
})

export class CBoxAdminEquipmentsListComponent extends PaginatedTableWithFilters<CBox_AdminEquipmentListItem[], CBox_AdminEquipmentListRequestData> implements OnInit {
  filtersForm: FormGroup;
  equipmentTypes = signal<CBox_EquipmentType[] | undefined>(undefined);
  dataFetchUrl = "internal/equipments/list";
  localStorageFiltersKey = "equipments-list/filters";
  localStorageFiltersToSave = ["pageSize"];
  sessionFiltersKey = "equipments-list/filters";
  sessionFiltersToSave = ["page", "lockerIdentifier", "equipmentType", "moduleSerial"];
  defaultFilters = signal({
    page: 1,
    pageSize: 100,
    includeInstalled: true,
    includeNotInstalled: true,
    lockerIdentifier: undefined,
    equipmentType: undefined,
    moduleSerial: ""
  });

  equipmentMenuItems = signal<Record<number, MenuItem[]>>({});

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private session: SessionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private lockerService: LockersService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    super(inj);
    this.filtersForm = this.fb.group({
      page: [1],
      pageSize: [100],
      includeInstalled: [true],
      includeNotInstalled: [true],
      lockerIdentifier: [undefined],
      equipmentType: [undefined],
      moduleSerial: [""]
    });
  }

  ngOnInit(): void {
    this.profileService.setTitle("Listă PickUps");
  }

  async init(): Promise<void> {
    await this.fetchEquipmentTypes();
  }

  createEquipment(): void {
    const dialog = this.dialog.open(CBoxAdminEquipmentCreateDialogComponent, {
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first(), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
    });
  }

  copyAndCreateEquipment(equipment?: CBox_AdminEquipmentListItem): void {
    if (!equipment) {
      return;
    }

    const dialog = this.dialog.open(CBoxAdminEquipmentCreateDialogComponent, {
      data: equipment,
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first(), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
    });
  }

  patchFilters(filters: {[key: string]: unknown}): void {
    this.filtersForm.patchValue({
      ...filters
    });
  }

  propagateLocker(identifier: string): void {
    const adminLockersListFilters = this.session.getVal("admin-lockers-list/filters", undefined);
    const adminLockerModulesFilters = this.session.getVal("admin-modules-list/filters", undefined);
    if (adminLockersListFilters) {
      const parsed = JSON.parse(adminLockersListFilters);
      parsed.lockerIdentifiers = [identifier];
      this.session.setVal("admin-lockers-list/filters", JSON.stringify(parsed));
    } else {
      const filters = {
        lockerIdentifiers: [identifier]
      };

      this.session.setVal("admin-lockers-list/filters", JSON.stringify(filters));
    }

    if (adminLockerModulesFilters) {
      const parsed = JSON.parse(adminLockerModulesFilters);
      parsed.lockerIdentifier = identifier;

      this.session.setVal("admin-modules-list/filters", JSON.stringify(parsed));
    } else {
      const filters = {
        lockerIdentifier: identifier
      };

      this.session.setVal("admin-modules-list/filters", JSON.stringify(filters));
    }

    this.patchFilters({
      lockerIdentifier: identifier
    });
  }

  propagateModule(serial: string): void {
    const adminLockerModulesFilters = this.session.getVal("admin-modules-list/filters", undefined);

    if (adminLockerModulesFilters) {
      const parsed = JSON.parse(adminLockerModulesFilters);
      parsed.serial = serial;
      this.session.setVal("admin-modules-list/filters", JSON.stringify(parsed));
    } else {
      const filters = {
        serial: serial
      };

      this.session.setVal("admin-modules-list/filters", JSON.stringify(filters));
    }

    this.patchFilters({
      moduleSerial: serial
    });
  }

  openMenu(equipment: CBox_AdminEquipmentListItem, event: MouseEvent): void {
    event.stopPropagation();
    const items = [
      {
        label: "Creează echipament similar",
        icon: "pi pi-plus",
        visible: true,
        command: () => {
          this.copyAndCreateEquipment(equipment);
        },
      }
    ];

    this.equipmentMenuItems.update(rows => {
      rows[equipment.id] = items.filter(item => item.visible);
      return rows;
    })
  }

  closeMenu(equipment: CBox_AdminEquipmentListItem): void {
    this.equipmentMenuItems.update(rows => {
      delete rows[equipment.id];
      return rows;
    });
  }

  private async fetchEquipmentTypes(): Promise<void> {
    const equipmentTypeField = this.filtersForm.get("equipmentType");
    return new Promise((resolve, reject) => {
      this.api
      .get<CBox_PublicSuccessResponse<CBox_EquipmentType[]>>("backend/internal/equipments/types")
      .subscribe((response) => {
          this.equipmentTypes.set(response.data);
          equipmentTypeField?.enable();
          resolve();
        }, (e: HttpErrorResponse) => {
          handlePublicApiError(e, this.toastService);
          equipmentTypeField?.patchValue(undefined);
          equipmentTypeField?.disable();
          reject();
        }
      )
    });
  }

  getSearchStructure(): CBox_AdminEquipmentListRequestData {
    return Object.entries({
      lockerIdentifier: this.filtersForm.value.lockerIdentifier,
      moduleSerial: this.filtersForm.value.moduleSerial,
      includeInstalled: !!this.filtersForm.value.includeInstalled,
      includeNotInstalled: !!this.filtersForm.value.includeNotInstalled,
      typeId: this.filtersForm.value.equipmentType,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value || value != null)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_AdminEquipmentListRequestData] = value;
      return acc;
    }, {} as CBox_AdminEquipmentListRequestData);
  }
}