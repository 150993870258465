<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>
    Adaugă todo
    @if (data.equipment) {
      <span class="font-bold">{{ data.equipment.typeName }}</span>
    }
  </h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form class="grid" [formGroup]="form">
    @if (data.lockerIdentifiers) {
      <div class="col-12">
        <span class="font-bold block mb-1">Lockere</span>
        <p-multiSelect
          formControlName="lockerIdentifiers"
          optionLabel="name"
          optionValue="id"
          styleClass="w-full"
          appendTo="body"
          placeholder="Caută lockere"
          filterBy="id,name"
          [showClear]="true"
          [options]="lockers()"
        >
          <ng-template let-locker pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>
                <small class="text-gray-400">#{{ locker?.id }}</small>
                <p class="m-0">{{ locker?.name }}</p>
              </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    }

    <div class="col-12">
      <span class="block font-bold mb-1">Detalii</span>
      <textarea
        class="w-full"
        rows="5"
        formControlName="text"
        placeholder="Descrieți task-ul"
        pInputTextarea
      ></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <p-button
    label="Adaugă"
    (click)="create()"
    [disabled]="!form.valid"
    [loading]="loading()"
  ></p-button>
</mat-dialog-actions>
@if (loading()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}