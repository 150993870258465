import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { CBox_GetLockerBaseData } from '@server/services/cbox/public/api/v1/resources/locker/types';
import { CBox_GetOpenLockerListDataResponse } from '@server/services/cbox/public/api/v1/resources/open/locker/types';
import { MapComponent } from 'app/map/map.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ApiService } from 'src/services/api/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ["./home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MapComponent,
    ProgressSpinnerModule
  ]
})

export class HomeComponent implements OnInit {

  lockers = signal<CBox_GetOpenLockerListDataResponse>([]);
  loading = signal(true);

  constructor(
    private api: ApiService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId))
    this.fetchLockers();
  }

  private fetchLockers(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_GetOpenLockerListDataResponse>>("backend/open/locker/list").subscribe((response) => {
      this.lockers.set(response.data);
      this.loading.set(false);
    });
  }
}