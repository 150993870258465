<form [formGroup]="filtersForm" class="grid align-items-end row-gap-1 mt-2">
  <!-- Date Range Filter -->
  <div class="col-12 lg:col-4 flex-order-1 lg:flex-order-0">
    <span class="font-bold block mb-2">Interval căutare</span>
    <p-calendar
      styleClass="w-full"
      dateFormat="dd/mm/yy"
      selectionMode="range"
      formControlName="interval"
      [showIcon]="true"
      [showOnFocus]="true"
      [placeholder]="'Data de la'"
      [panelStyle]="{ 'height': '439px' }">
    </p-calendar>
  </div>

  <!-- Lockers MultiSelect Filter -->
  <div class="col-12 lg:col-4 flex-order-2 lg:flex-order-1">
    <span class="font-bold block mb-1">Lockere</span>
    <p-multiSelect
      formControlName="lockers"
      optionLabel="name"
      optionValue="id"
      placeholder="Filtrează lockere"
      styleClass="w-full"
      appendTo="body"
      filterBy="id,name"
      [options]="lockers()"
      [loading]="!lockers()">
      <ng-template let-locker pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            <small class="text-gray-400">#{{ locker?.id }}</small>
            <p class="m-0">{{ locker?.name }}</p>
          </div>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>

  <!-- Generate Report Button -->
  <div class="col-12 flex-order-0 lg:flex-order-2 lg:col-4 text-right">
    <p-button
      label="Generează raport"
      icon="pi pi-file-plus"
      (onClick)="generateReport()">
    </p-button>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm"
  currentPageReportTemplate="{first} - {last} din {totalRecords} itemi"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="tableItems"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)">

  <!-- Table Header -->
  <ng-template pTemplate="header">
    <!-- Loading Indicator -->
    @if (loading()) {
      <tr>
        <td colspan="10">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
        </td>
      </tr>
    }

    <!-- Column Headers -->
    <tr>
      <th>Locker</th>
      <th class="text-center">Ridicate de clienți</th>
      <th class="text-center">0-2 ore</th>
      <th class="text-center">2-4 ore</th>
      <th class="text-center">4-12 ore</th>
      <th class="text-center">12-24 ore</th>
      <th class="text-center">24-36 ore</th>
      <th class="text-center">+36 ore</th>
      <th class="text-center">Total plasate</th>
      <th class="text-center">Total recuperate / anulate</th>
    </tr>

    <!-- Totals Row -->
    <tr>
      <th>Total perioadă</th>

      <!-- Total Livrate -->
      <th class="text-center">
        {{ summary()?.totalPickedUp }}
        @if (summary()) {
          ({{ calculatePercentFromTotal(summary()?.totalPickedUp!, summary()?.totalDelivered!) | number:"1.2-2" }}%)
        }
      </th>

      <!-- 0-2 ore -->
      <th class="text-center">
        {{ summary()?.interval_0_2 }}
        @if (summary()) {
          ({{ calculatePercentFromTotal(summary()?.interval_0_2!, summary()?.totalPickedUp!) | number:"1.2-2" }}%)
        }
      </th>

      <!-- 2-4 ore -->
      <th class="text-center">
        {{ summary()?.interval_2_4 }}
        @let total_2_4 = (
          calculatePercentFromTotal(summary()?.interval_0_2!, summary()?.totalPickedUp!) +
          calculatePercentFromTotal(summary()?.interval_2_4!, summary()?.totalPickedUp!)
        );
        @if (summary()) {
          (+{{ calculatePercentFromTotal(summary()?.interval_2_4!, summary()?.totalPickedUp!) | number:"1.2-2" }}% / {{ total_2_4 | number:"1.2-2" }}%)
        }
      </th>

      <!-- 4-12 ore -->
      <th class="text-center">
        {{ summary()?.interval_4_12 }}
        @let total_4_12 = (
          calculatePercentFromTotal(summary()?.interval_4_12!, summary()?.totalPickedUp!) + total_2_4
        );
        @if (summary()) {
          (+{{ calculatePercentFromTotal(summary()?.interval_4_12!, summary()?.totalPickedUp!) | number:"1.2-2" }}% / {{ total_4_12 | number:"1.2-2" }}%)
        }
      </th>

      <!-- 12-24 ore -->
      <th class="text-center">
        {{ summary()?.interval_12_24 }}
        @let total_12_24 = (
          calculatePercentFromTotal(summary()?.interval_12_24!, summary()?.totalPickedUp!) + total_4_12
        );
        @if (summary()) {
          (+{{ calculatePercentFromTotal(summary()?.interval_12_24!, summary()?.totalPickedUp!) | number:"1.2-2" }}% / {{ total_12_24 | number:"1.2-2" }}%)
        }
      </th>

      <!-- 24-36 ore -->
      <th class="text-center">
        {{ summary()?.interval_24_36 }}
        @let total_24_36 = (
          calculatePercentFromTotal(summary()?.interval_24_36!, summary()?.totalPickedUp!) + total_12_24
        );
        @if (summary()) {
          (+{{ calculatePercentFromTotal(summary()?.interval_24_36!, summary()?.totalPickedUp!) | number:"1.2-2" }}% / {{ total_24_36 | number:"1.2-2" }}%)
        }
      </th>

      <!-- +36 ore -->
      <th class="text-center">
        {{ summary()?.interval_36plus }}
        @let total_36plus = (
          calculatePercentFromTotal(summary()?.interval_36plus!, summary()?.totalPickedUp!) + total_24_36
        );
        @if (summary()) {
          (+{{ calculatePercentFromTotal(summary()?.interval_36plus!, summary()?.totalPickedUp!) | number:"1.2-2" }}% / {{ total_36plus | number:"1.2-2" }}%)
        }
      </th>

      <!-- Total Plasate -->
      <th class="text-center">
        {{ summary()?.totalDelivered }}
        @if (summary()) {
          (100%)
        }
      </th>

      <!-- Total Recuperate / Anulate -->
      <th class="text-center">
        {{ summary()?.totalRetrieved }}
        @if (summary()) {
          ({{ calculatePercentFromTotal(summary()?.totalRetrieved!, summary()?.totalDelivered!) | number:"1.2-2" }}%)
          /
        }
        {{ summary()?.totalCancelled }}
        @if (summary()) {
          ({{ calculatePercentFromTotal(summary()?.totalCancelled!, summary()?.totalDelivered!) | number:"1.2-2" }}%)
        }
      </th>
    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-report>
    <tr>
      @let locker = report.locker;
      @let totals = report.totals;

      <!-- Locker Info -->
      <td>
        <div class="flex align-items-center gap-2">
          <div>
            <small class="text-gray-400">#{{ locker?.identifier }}</small>
            <p class="m-0">{{ locker?.name }}</p>
            <small>{{ lockerCleanAddress(locker?.addressParts) }}</small>
          </div>
        </div>
      </td>

      <!-- Individual Totals -->
      @let pickedUpPercent = calculatePercentFromTotal(totals.pickedUp, totals.shipments);
      @let putPercent = calculatePercentFromTotal(summary()?.totalPickedUp!, summary()?.totalDelivered!);
      <td [ngClass]="{
        'text-orange-500': pickedUpPercent < putPercent,
        'text-red-500': pickedUpPercent < (putPercent - 10),
        'text-green-500': pickedUpPercent >= putPercent
      }" class="text-center">
        {{ totals.pickedUp }}
        ({{ calculatePercentFromTotal(totals.pickedUp, totals.shipments) | number:"1.2-2" }}%)
      </td>

      <!-- Bucket Data -->
      @for (bucketItem of groupPickUpTimesForItem(report); track $index) {
        <td class="text-center">
          {{ bucketItem.count }}
          @if ($index === 0) {
            ({{ bucketItem.cumulatedPercentageFromTotal | number:"1.2-2" }}%)
          } @else {
            (+{{ calculatePercentFromTotal(bucketItem.count, totals.pickedUp) | number:"1.2-2" }}%
            / {{ bucketItem.cumulatedPercentageFromTotal | number:"1.2-2" }}%)
          }
        </td>
      }
      <td class="text-center">{{ totals.shipments }}</td>
      <td class="text-center">
        {{ totals.retrieved }}
        ({{ calculatePercentFromTotal(totals.retrieved, totals.shipments) | number:"1.2-2" }}%)
        /
        {{ totals.cancelled }}
        ({{ calculatePercentFromTotal(totals.cancelled, totals.shipments) | number:"1.2-2" }}%)
      </td>
    </tr>
  </ng-template>

  <!-- Empty Message -->
  <ng-template pTemplate="emptymessage">
    @if (!loading()) {
      <tr>
        <td colspan="10">
          <p class="text-center text-gray-400 text-xl font-bold">
            Nu există rapoarte
          </p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>
