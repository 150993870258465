@if (loading()) {
  <div class="map-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
} @else {
  <div class="map-container relative">
    <h1 class="absolute top-2rem">Locații cBox</h1>
    @defer {
      <app-map
        class="w-full h-full"
        [lockers]="lockers()"
      ></app-map>
    }
  </div>
}