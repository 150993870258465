<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Editează utilizator</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="userForm" class="grid">
    @if (oldUniqueIdentifier() !== userForm.get("identifier")?.value && oldUniqueIdentifier()) {
      <p-messages
        styleClass="text-xs mt-0"
        class="col-12"
        [value]="identifierChangeWarning()"
        [enableService]="false"
        [closable]="false"
      />
    }

    <div class="col-12 md:col-6">
      <span class="block font-bold mb-1">Nume</span>
      <input
        autocomplete="off"
        class="w-full"
        type="text"
        pInputText
        placeholder="Nume"
        pAutoFocus
        [autofocus]="true"
        formControlName="name"
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Identificator</span>
      <input
        formControlName="identifier"
        class="w-full"
        id="awb"
        pInputText
      >
    </div>

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-1">Email</span>
      @let email = userForm.get("email");
      <input
        class="w-full"
        type="text"
        pInputText
        [placeholder]="email?.disabled ? '' : 'test@companie.ro'"
        formControlName="email"
      >
      @let emailErrors = userForm.get("email")?.errors;
      @if (email?.dirty) {
        @if (emailErrors) {
          @if (emailErrors?.["required"]) {
            <small class="text-red-500">Email-ul este obligatoriu!</small>
          }
        }
      }
      @if (!userHasEmail()) {
        <div class="flex align-items-center gap-2">
          <p-checkbox
            class="mt-1"
            [binary]="true"
            formControlName="noEmailAddress"
            inputId="noEmailAddress"
            (onChange)="noEmailAddressToggled(); email?.reset();"
          ></p-checkbox>
          <label class="-mb-1 text-xs" for="noEmailAddress">Utilizatorul nu are adresă de email</label>
        </div>
      }
    </div>

    <div
      class="col-12 sm:col-6"
    >
      <span class="font-bold block mb-1">Telefon</span>
      @let phone = userForm.get("phone");
      <input
        class="w-full"
        type="tel"
        pKeyFilter="int"
        pInputText
        [placeholder]="phone?.disabled ? '' : '07xxxxxxxx'"
        formControlName="phone"
      />
      @let phoneErrors = userForm.get("phone")?.errors;
      @if (phone?.dirty) {
        @if (phoneErrors) {
          @if (phoneErrors?.["required"]) {
            <small class="text-red-500">Numarul de telefon este obligatoriu!</small>
          }
        }
      }
      <div class="flex align-items-center gap-2">
        <p-checkbox
          class="mt-1"
          [binary]="true"
          formControlName="noPhoneNumber"
          inputId="noPhoneNumber"
          (onChange)="noPhoneNumberToggled(); phone?.reset();"
        ></p-checkbox>
        <label class="-mb-1 text-xs" for="noPhoneNumber">Utilizatorul nu are număr de telefon</label>
      </div>
    </div>

    <div class="col-12">
      <span class="block font-bold mb-1">Rol</span>
      <p-dropdown
        optionLabel="roleName"
        optionValue="roleName"
        styleClass="w-full"
        placeholder="Selectati un rol"
        formControlName="role"
        [options]="roles()"
        [loading]="!roles()"
      ></p-dropdown>
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Zonă</span>
      <p-dropdown
        formControlName="zoneId"
        optionLabel="name"
        optionValue="id"
        placeholder="Selectează o zonă"
        styleClass="w-full"
        appendTo="body"
        [options]="zones()"
        [loading]="!zonesFetched()"
      />
      @let zone = userForm.get("zoneId");
      @let zoneErrors = zone?.errors;
      @if (zone?.dirty && zone?.touched) {
        @if (zoneErrors?.["minlength"]) {
          <small class="text-red-500">Vă rugăm să selectați zona de acces</small>
        }
      }
    </div>

    <div class="col-12">
      <span class="font-bold block mb-1">Notițe</span>
      <textarea
        class="w-full"
        rows="5"
        cols="30"
        pInputTextarea
        formControlName="notes"
      ></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    class="w-full"
    label="Salvează"
    [disabled]="!userForm.valid"
    (click)="saveUser()"
  ></button>
</mat-dialog-actions>
@if (userSaveStatus() === "saving" || userSaveStatus() === "fetching"){
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}

