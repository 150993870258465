// Enums for importance levels and procedure types
export enum CBox_EventImportanceLevel {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}

export enum CBox_EventProceduresType {
  PROCEDURE_START = "PROCEDURE_START",
  // PROCEDURE_ACTION = "PROCEDURE_ACTION",
  PROCEDURE_END = "PROCEDURE_END",
  SUB_PROCEDURE_START = "SUB_PROCEDURE_START",
  // LABEL_PRINT_REQUESTED = "LABEL_PRINT_REQUESTED",
  PROMPT_SHOWN = "PROMPT_SHOWN",
  PROMPT_SELECTION = "PROMPT_SELECTION",
  CODE_SCAN = "CODE_SCAN",
  BOX_OPEN_FAILED = "BOX_OPEN_FAILED",
  BOX_CLOSE_FAILED = "BOX_CLOSE_FAILED",
  LABEL_PRINT_FAILED = "LABEL_PRINT_FAILED",
  BOX_TOO_SMALL = "BOX_TOO_SMALL",
  BOX_OPENED = "BOX_OPENED",
  BOX_CLOSED = "BOX_CLOSED",
  SYSTEM_COMMAND = "SYSTEM_COMMAND",
  SYSTEM_EVENT = "SYSTEM_EVENT",
  ERROR_HANDLER = "ERROR_HANDLER",
  // to test connection
  TEST_CONNECTION = "TEST_CONNECTION",
  // Unprocessable event
  UNPROCESSABLE = "UNPROCESSABLE",
}

export enum CBox_EventPromptSelectionEnum {
  LABEL_REQUIRED = "LABEL_REQUIRED",
  LABEL_PRINT_FAILED = "LABEL_PRINT_FAILED",
  LABEL_PRINTED = "LABEL_PRINT",
  BOX_OPEN_FAILED = "BOX_OPEN_FAILED",
  // USER-DROP-OFF
  USER_DROP_OFF_ORDER_BUTTONS = "USER_DROP_OFF_ORDER_BUTTONS",
  USER_DROP_OFF_ORDER_BIGGER_BOX = "USER_DROP_OFF_ORDER_BIGGER_BOX",
  USER_DROP_OFF_ORDER_BOX_NOT_CLOSING = "USER_DROP_OFF_ORDER_BOX_NOT_CLOSING",

  // COURIER-PUT
  COURIER_PUT_ORDER_MAIN_BUTTONS = "COURIER_PUT_ORDER_MAIN_BUTTONS",
  COURIER_PUT_ORDER_PROMPT_BIGGER_BOX = "COURIER_PUT_ORDER_PROMPT_BIGGER_BOX",
  COURIER_PUT_ORDER_PROMPT_BOX_NOT_CLOSING =
      "COURIER_PUT_ORDER_PROMPT_BOX_NOT_CLOSING",
  COURIER_PUT_ORDER_PROMPT_CONFIRM_ORDER =
      "COURIER_PUT_ORDER_PROMPT_CONFIRM_ORDER",
  // COURIER-RETRIEVE
  COURIER_RETRIEVE_ORDER_BUTTON = "COURIER_RETRIEVE_ORDER_BUTTON",
}

export enum CBox_EventPromptBoxFailedAnswerEnum {
  BOX_OPEN_FAILED_WRONG = "BOX_OPEN_FAILED_WRONG",
  BOX_OPEN_FAILED_RIGHT = "BOX_OPEN_FAILED_RIGHT"
}

export enum CBox_EventPromptLabelRequiredAnswerEnum {
  LABEL_REQUIRED_PRINT_REQUESTED = "LABEL_REQUIRED_PRINT_REQUESTED",
}

export enum CBox_EventPromptLabelPrintFailedAnswerEnum {
  LABEL_PRINT_FAILED = "LABEL_PRINT_FAILED",
  LABEL_PRINT_TIME_EXTENSION_REQUESTED = "LABEL_PRINT_TIME_EXTENSION_REQUESTED",
  LABEL_PRINT_OK = "LABEL_PRINT_OK"
}

export enum CBox_EventPromptLabelPrintedAnswerEnum {
  LABEL_PRINT_FAILED = "LABEL_PRINT_FAILED",
  LABEL_PRINT_SUCCESS = "LABEL_PRINT_SUCCESS"
}

export enum CBox_EventPromptShownSelectionEnum {
  CLOSE_DOORS_REQUEST = "CLOSE_DOORS_REQUEST",
  NO_BOXES_AVAILABLE = "NO_BOXES_AVAILABLE",
}

// Define a generic base event interface
export interface CBoxBaseEvent<
  T extends CBox_EventProceduresType,
  I extends CBox_EventImportanceLevel,
  D
> {
  type: T;
  importance: I;
  procedureId: string;
  eventId: string; // Unique ID to prevent event overlap
  timestamp: string; // Timestamp of when the event occurred
  data: D;
}

export type CBox_SubProcedureTypes =
    "NONE"|"COURIER_CLOSE_DOORS"|"COURIER_RETRIEVE_EXPIRED"|
    "COURIER_TAKE_ORDERS"|"COURIER_PUT_ORDERS"|"CHANGE_PAPER" |
    "INTERNAL_TESTING";

// Define data interfaces for each event type
export type CBox_EventSubProcedureStartData = {
  subProcedureType: CBox_SubProcedureTypes;
};

// export interface CBoxEventDoorOpenActionData {
//   uniqueBoxId: number;
// }

// export interface CBoxEventDoorCloseActionData {
//   uniqueBoxId: number;
//   rightDoor: boolean; // Indicates if the correct door was closed
// }

export type CBox_EventBoxTooSmallData = {
  uniqueBoxId: number; // Door declared too small for the package
};

export type CBox_EventUserPromptShownData = {
  type: CBox_EventPromptShownSelectionEnum.CLOSE_DOORS_REQUEST;
  requestedDoorsUniqueIds: number[];
}  | {
  type: CBox_EventPromptShownSelectionEnum.NO_BOXES_AVAILABLE;
};

enum CBox_ConfirmOrderReason {
  LABEL_SCAN = "LABEL_SCAN",
  USER_ACTION = "USER_ACTION",
  PROCEDURE_END = "PROCEDURE_END",
  MAX_DOORS_NOT_OPENING_TRIES_REACHED = "MAX_DOORS_NOT_OPENING_TRIES_REACHED",
  NO_DOORS_AVAILABLE = "NO_DOORS_AVAILABLE"
}

export type CBox_EventUserPromptSelectionData = {
  type: CBox_EventPromptSelectionEnum.BOX_OPEN_FAILED;
  answer: CBox_EventPromptBoxFailedAnswerEnum;
  uniqueBoxId: number;
  usageReason: CBox_EventBoxUsageReason;
  skippedSizes: number[];
} | {
  type: CBox_EventPromptSelectionEnum.LABEL_REQUIRED;
  answer: CBox_EventPromptLabelRequiredAnswerEnum;
} | {
  type: CBox_EventPromptSelectionEnum.LABEL_PRINT_FAILED;
  answer: CBox_EventPromptLabelPrintFailedAnswerEnum;
} | {
  type: CBox_EventPromptSelectionEnum.LABEL_PRINTED;
  answer: CBox_EventPromptLabelPrintFailedAnswerEnum;
} | {
  type: CBox_EventPromptSelectionEnum.COURIER_PUT_ORDER_MAIN_BUTTONS;
  answer: "BIGGER_BOX" | "BIGGER_BOX_AFTER_CLOSE" | "BOX_NOT_CLOSING";
} | {
  type: CBox_EventPromptSelectionEnum.COURIER_PUT_ORDER_PROMPT_BIGGER_BOX;
  answer: "REVERT_TO_PREV_BOX"
} | {
  type: CBox_EventPromptSelectionEnum.COURIER_PUT_ORDER_PROMPT_BOX_NOT_CLOSING;
  answer: "CONFIRM" | "REVERT"
} | {
  type: CBox_EventPromptSelectionEnum.COURIER_PUT_ORDER_PROMPT_CONFIRM_ORDER;
  uniqueBoxId: number | null;
  usageReason?: CBox_EventBoxUsageReason;
  skippedSizes?: number[];
  reason: CBox_ConfirmOrderReason;
} | {
  type: CBox_EventPromptSelectionEnum.COURIER_RETRIEVE_ORDER_BUTTON;
  answer: "BOX_NOT_CLOSING" | "BOX_DID_NOT_OPEN";
} | {
  type: CBox_EventPromptSelectionEnum.USER_DROP_OFF_ORDER_BUTTONS;
  answer: "BIGGER_BOX" | "BOX_NOT_CLOSING";
} | {
  type: CBox_EventPromptSelectionEnum.USER_DROP_OFF_ORDER_BOX_NOT_CLOSING;
  answer: "CONFIRM" | "REVERT"
} | {
  type: CBox_EventPromptSelectionEnum.USER_DROP_OFF_ORDER_BIGGER_BOX;
  answer: "REVERT_TO_PREV_BOX"
};

export type CBox_EventLabelScanData = {
  awb: string; // Air Waybill number
};

export type CBox_EventBoxFailReportedBy = "USER" | "AGENT" | "SYSTEM";

export type CBox_EventBoxOpenFailedData = {
  uniqueBoxId: number;
  reportedBy: CBox_EventBoxFailReportedBy;
};

export type CBox_EventBoxCloseFailedData = {
  uniqueBoxId: number;
  reportedBy: CBox_EventBoxFailReportedBy;
};

export type CBox_EventBoxUsageReason = "NONE" | "PROJECTED_BOX_USED"|
    "LARGER_BOX_DUE_TO_AVAILABILITY"|"LARGER_BOX_DUE_TO_PACKAGE_SIZE";

export type CBox_EventBoxClosedData = {
  uniqueBoxId: number;
  isRightBox: boolean;
  usageReason?: CBox_EventBoxUsageReason; // required if isRightBox is true
  skippedSizes: number[]; // required if isRightBox is true
};

export type CBox_EventLabelPrintFailedData = {
};

export type CBox_EventProcedureEndData = {
  success: boolean;
};

export type CBox_EventBoxOpenedData = {
  uniqueBoxId: number;
  isRightBox: boolean;
};

export enum CBox_SystemEvents {
  BOOT_START = "BOOT_START",
  INTERNET_CONNECTION = "INTERNET_CONNECTION",
}

export type CBox_SystemEventsData = {
  type: CBox_SystemEvents.BOOT_START;
} | {
  type: CBox_SystemEvents.INTERNET_CONNECTION;
  online: boolean;
};

export type CBox_EventSystemCommandData = {
  isAbort: boolean;
  message: string;
  context: string;
};

export const CBoxUndefinedRequestId = "UNDEFINED_REQUEST_ID";

type CBox_EventSubProcedureExtraData = {
  subProcedureType: CBox_SubProcedureTypes;
  requestId: string;
};

// Now, define specific event interfaces using the updated BaseEvent

// PROCEDURE_START event
export interface CBoxProcedureStartEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.PROCEDURE_START,
    CBox_EventImportanceLevel.LOW,
    {}
  > {}

// SUB_PROCEDURE_START event
export interface CBoxSubProcedureStartEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.SUB_PROCEDURE_START,
    CBox_EventImportanceLevel.LOW,
    CBox_EventSubProcedureStartData
  > {}

export interface CBoxBoxClosedEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.BOX_CLOSED,
    CBox_EventImportanceLevel.CRITICAL,
    CBox_EventBoxClosedData & CBox_EventSubProcedureExtraData
  > {}
// PROCEDURE_ACTION events with subtypes

// // DOOR_OPEN_ACTION event
// export interface CBoxDoorOpenActionEvent
//   extends CBox_BaseEvent<
//     CBox_EventProceduresType.PROCEDURE_ACTION,
//     CBox_EventImportanceLevel.MEDIUM,
//     CBox_EventProceduresActions.DOOR_OPEN_ACTION,
//     CBox_EventDoorOpenActionData
//   > {}

// // DOOR_CLOSE_ACTION event
// export interface CBoxDoorCloseActionEvent
//   extends CBox_BaseEvent<
//     CBox_EventProceduresType.PROCEDURE_ACTION,
//     CBox_EventImportanceLevel.MEDIUM,
//     CBox_EventProceduresActions.DOOR_CLOSE_ACTION,
//     CBox_EventDoorCloseActionData
//   > {}

// // LABEL_PRINT_REQUESTED event
// export interface CBoxLabelPrintRequestedEvent
//   extends CBox_BaseEvent<
//     CBox_EventProceduresType.LABEL_PRINT_REQUESTED,
//     CBox_EventImportanceLevel.LOW,
//     undefined,
//     {}
//   > {}

// BOX_TOO_SMALL event
export interface CBoxBoxTooSmallEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.BOX_TOO_SMALL,
    CBox_EventImportanceLevel.MEDIUM,
    CBox_EventBoxTooSmallData & CBox_EventSubProcedureExtraData
  > {}

export interface CBoxPromptShownEvent extends
  CBoxBaseEvent<CBox_EventProceduresType.PROMPT_SHOWN,
    CBox_EventImportanceLevel.MEDIUM,
    CBox_EventUserPromptShownData & CBox_EventSubProcedureExtraData
  > {}

// USER_PROMPT_SELECTION event
export interface CBoxPromptSelectionEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.PROMPT_SELECTION,
    CBox_EventImportanceLevel.MEDIUM,
    CBox_EventUserPromptSelectionData & CBox_EventSubProcedureExtraData
  > {}

// LABEL_SCAN event
export interface CBoxCodeScanEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.CODE_SCAN,
    CBox_EventImportanceLevel.LOW,
    CBox_EventLabelScanData & CBox_EventSubProcedureExtraData
  > {}

// DOOR_OPEN_FAILED event
export interface CBoxBoxOpenFailedEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.BOX_OPEN_FAILED,
    CBox_EventImportanceLevel.CRITICAL,
    CBox_EventBoxOpenFailedData & CBox_EventSubProcedureExtraData
  > {}

// DOOR_CLOSE_FAILED event
export interface CBoxBoxCloseFailedEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.BOX_CLOSE_FAILED,
    CBox_EventImportanceLevel.HIGH,
    CBox_EventBoxCloseFailedData & CBox_EventSubProcedureExtraData
  > {}

// LABEL_PRINT_FAILED event
export interface CBoxLabelPrintFailedEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.LABEL_PRINT_FAILED,
    CBox_EventImportanceLevel.CRITICAL,
    CBox_EventLabelPrintFailedData & CBox_EventSubProcedureExtraData
  > {}

// PROCEDURE_END event
export interface CBoxProcedureEndEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.PROCEDURE_END,
    CBox_EventImportanceLevel.LOW,
    CBox_EventProcedureEndData
  > {}

export interface CBoxBoxOpenedEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.BOX_OPENED,
    CBox_EventImportanceLevel.CRITICAL,
    CBox_EventBoxOpenedData & CBox_EventSubProcedureExtraData
  > {}

export interface CBoxSystemCommandEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.SYSTEM_COMMAND,
    CBox_EventImportanceLevel.MEDIUM,
    CBox_EventSystemCommandData
  > {}

export interface CBoxSystemEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.SYSTEM_EVENT,
    CBox_EventImportanceLevel.LOW,
    CBox_SystemEventsData
  > {}

export interface CBoxTestConnectionEvent
  extends CBoxBaseEvent<
    CBox_EventProceduresType.TEST_CONNECTION,
    CBox_EventImportanceLevel.MEDIUM,
    {}
  > {}

export interface CBoxErrorHandlerEvent
extends CBoxBaseEvent<
  CBox_EventProceduresType.ERROR_HANDLER,
  CBox_EventImportanceLevel.CRITICAL,
  {
    error: string;
    stackTrace: string;
  } & CBox_EventSubProcedureExtraData
> {}

export interface CBoxUnprocessableEvent
  extends CBoxBaseEvent<
  CBox_EventProceduresType.UNPROCESSABLE,
  CBox_EventImportanceLevel.CRITICAL,
  {
    data: CBox_ProcedureEvent;
  }
> {}

// Union type of all possible events
export type CBox_ProcedureEvent =
  | CBoxProcedureStartEvent
  | CBoxSubProcedureStartEvent
  | CBoxTestConnectionEvent
  | CBoxUnprocessableEvent
  | CBoxErrorHandlerEvent
  // | CBox_DoorOpenActionEvent
  // | CBox_DoorCloseActionEvent
  // | CBox_LabelPrintRequestedEvent
  | CBoxBoxTooSmallEvent
  | CBoxPromptShownEvent
  | CBoxPromptSelectionEvent
  | CBoxCodeScanEvent
  | CBoxBoxOpenFailedEvent
  | CBoxBoxCloseFailedEvent
  | CBoxLabelPrintFailedEvent
  | CBoxBoxOpenedEvent
  | CBoxBoxClosedEvent
  | CBoxSystemEvent
  | CBoxSystemCommandEvent
  | CBoxProcedureEndEvent;

type ExtractData<T> = T extends { data: infer U } ? U : never;

export type CBox_ProcedureEventData = ExtractData<CBox_ProcedureEvent>;
