import { CommonModule } from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken,
  Injector,
  OnInit,
  PLATFORM_ID,
  signal
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { LockersService } from 'src/services/lockers/lockers.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { SessionService } from 'src/services/session/session.service';
import { CBox_AdminModuleListItemDataResponse, CBox_AdminModuleListRequestData } from "@server/services/cbox/public/api/v1/resources/internal/locker_module/types";
import { first } from 'rxjs';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { CBoxAdminModuleCreateComponent } from '../dialogs/create/cbox-admin-module-create.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CBoxAdminModuleInfoComponent } from '../dialogs/info/cbox-admin-module-info.component';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { PaginatedTableWithFilters } from 'app/shared/paginated-table-with-filters';

@Component({
  selector: 'app-cbox-admin-locker-modules-list',
  templateUrl: './cbox-admin-locker-modules-list.component.html',
  styleUrls: ["./cbox-admin-locker-modules-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    MenuModule,
    ProgressBarModule,
    ReactiveFormsModule,
    CheckboxModule,
    DropdownModule,
    InputTextModule,
    InputIconModule,
    IconFieldModule
  ]
})

export class CBoxAdminLockerModulesListComponent extends PaginatedTableWithFilters<CBox_AdminModuleListItemDataResponse[], CBox_AdminModuleListRequestData> implements OnInit {
  filtersForm: FormGroup;
  sessionFiltersKey = "admin-modules-list/filters";
  localStorageFiltersKey = "admin-modules-list/filters";
  sessionFiltersToSave = ["filter", "page", "serial", "lockerIdentifier"];
  localStorageFiltersToSave = ["pageSize"];
  dataFetchUrl = "internal/modules/list";
  defaultFilters = signal({
    page: 1,
    pageSize: 100,
    serial: "",
    filter: "ALL",
    lockerIdentifier: undefined
  });
  moduleTypes = signal([
    {
      id: "ALL",
      label: "Toate"
    },
    {
      id: "ATTACHED",
      label: "Atașate"
    },
    {
      id: "NOT_ATTACHED",
      label: "Neatașate"
    }
  ]);

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private session: SessionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private lockerService: LockersService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    super(inj);
    this.filtersForm = this.fb.group({
      page: [1],
      pageSize: [100],
      filter: ["ALL"],
      serial: [""],
      lockerIdentifier: [undefined]
    });
  }

  ngOnInit(): void {
    this.profileService.setTitle("Listă module");
  }

  patchFilters(filters: {[key: string]: string}) {
    this.filtersForm.patchValue(filters);
  }

  async init(): Promise<void> {
    this.profileService.setTitle("Listă module");

    this.lockers.set(await this.lockerService.getList());

    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const defaultFilters = {
      page: 1,
      pageSize: 100,
      serial: "",
      filter: "ALL",
      lockerIdentifier: undefined
    };

    const cachedFilters = this.session.getVal("admin-modules-list/filters", undefined);

    if (cachedFilters) {
      const parsedFilters = JSON.parse(cachedFilters);

      this.filtersForm.patchValue(
        {
          ...defaultFilters,
          ...parsedFilters,
        },
        { emitEvent: false }
      );

      if (parsedFilters?.lockerIdentifier?.length) {
        this.filtersForm.patchValue({
          filter: "ALL"
        }, {
          emitEvent: false
        });
        this.filtersForm.get("filter")?.disable({
          emitEvent: false
        });
      } else {
        this.filtersForm.get("filter")?.enable({
          emitEvent: false
        });
      }
    } else {
      this.filtersForm.patchValue(defaultFilters, { emitEvent: false });
    }

    this.fetchData();
  }

  protected override patchCachedFilters(): void {
    const defaultFilters = this.defaultFilters();

    const cachedFilters = this.session.getVal("admin-modules-list/filters", undefined);

    if (cachedFilters) {
      const parsedFilters = JSON.parse(cachedFilters);

      this.filtersForm.patchValue(
        {
          ...defaultFilters,
          ...parsedFilters,
        },
        { emitEvent: false }
      );

      if (parsedFilters?.lockerIdentifier?.length) {
        this.filtersForm.patchValue({
          filter: "ALL"
        }, {
          emitEvent: false
        });
        this.filtersForm.get("filter")?.disable({
          emitEvent: false
        });
      } else {
        this.filtersForm.get("filter")?.enable({
          emitEvent: false
        });
      }
    } else {
      this.filtersForm.patchValue(defaultFilters, { emitEvent: false });
    }
  }

  openModuleInfo(module: CBox_AdminModuleListItemDataResponse): void {
    const dialog = this.dialog.open(CBoxAdminModuleInfoComponent, {
      data: +module.id,
      minWidth: "min(800px, 100%)",
      autoFocus: false,
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  createModule(): void {
    const dialog = this.dialog.open(CBoxAdminModuleCreateComponent, {
      minWidth: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  getSearchStructure() {
    const values = this.filtersForm.value;

    const params: CBox_AdminModuleListRequestData = {
      filter: values.filter,
      page: values.page,
      pageSize: values.pageSize,
      serial: values.serial,
      lockerIdentifier: values.lockerIdentifier
    };

    Object.keys(params).forEach(
        (key) =>
            (params[key as keyof CBox_AdminModuleListRequestData] == null ||
             params[key as keyof CBox_AdminModuleListRequestData] === '') &&
            delete params[key as keyof CBox_AdminModuleListRequestData]);

    return params;
  }
}