@if (sessionData()) {
  <div class="dialog-header flex align-items-center justify-content-between w-full">
    <div>
      <small class="text-gray-400">{{ sessionData()?.agent?.identifier }}</small>
      <h2>Sesiune {{ sessionData()?.agent?.name }}</h2>
      <div class="flex align-items-center text-gray-400">
        <span class="material-symbols-rounded md-18">calendar_clock</span>
        <p class="m-0 text-xs">
          {{ sessionData()?.activity?.start | date: 'dd MMMM HH:mm' }} - {{ sessionData()?.activity?.end | date: 'dd MMMM HH:mm' }}
        </p>
      </div>
    </div>
    <p-button
      mat-dialog-close
      icon="pi pi-times"
      severity="secondary"
      size="small"
      [rounded]="true"
      [text]="true"
    />
  </div>
} @else {
  <div class="dialog-header flex align-items-center justify-content-between w-full">
    <div>
      <p-skeleton width="5rem" height="1rem" borderRadius="100vw"></p-skeleton>
      <p-skeleton width="15rem" height="1.75rem" borderRadius="100vw" styleClass="mt-1"></p-skeleton>
      <div class="flex align-items-center gap-1 text-gray-400 mt-1">
        <p-skeleton width="1rem" height="1.125rem" borderRadius=".25rem"></p-skeleton>
        <p-skeleton width="15rem" height="0.875rem" borderRadius="100vw"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="3rem" height="3rem" borderRadius="100vw"></p-skeleton>
  </div>
}
<mat-dialog-content>
  @if (sessionData()) {
    <div class="surface-100 border-round px-4 py-3 mb-2">
      <small class="text-gray-600">#{{ sessionData()?.locker?.identifier }}</small>
      <h3 class="m-0">{{ sessionData()?.locker?.name }}</h3>
      <p class="text-gray-600 m-0">{{ formatLockerAddress(sessionData()?.locker?.addressParts) }}</p>
    </div>
    <p-accordion>
      <p-accordionTab [header]="'Comenzi livrate ' + '(' + getActionParcelCount(actions.COURIER_PUT_ORDERS) + ')'">
        <p-table
          [value]="getFilteredActions(actions.COURIER_PUT_ORDERS)"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>AWB</th>
              <th>Referință unică</th>
              <th>Oră</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-action>
            <tr
              class="hover:surface-100 cursor-pointer"
              (click)="openOrderInfo(action)"
            >
              <td>{{ action.request.awb }}</td>
              <td>{{ action.request.uniqueReference }}</td>
              <td>
                {{ action.request.occurredAt | date: 'HH:mm' }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
      <p-accordionTab [header]="'Comenzi recuperate ' + '(' + getActionParcelCount(actions.COURIER_RETRIEVE_EXPIRED) + ')'">
        <p-table
          [value]="getFilteredActions(actions.COURIER_RETRIEVE_EXPIRED)"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>AWB</th>
              <th>Referință unică</th>
              <th>Oră</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-action>
            <tr
              class="hover:surface-100 cursor-pointer"
              (click)="openOrderInfo(action)"
            >
              <td>{{ action.request.awb }}</td>
              <td>{{ action.request.uniqueReference }}</td>
              <td>
                {{ action.request.occurredAt | date: 'HH:mm' }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
      <p-accordionTab [header]="'Comenzi ridicate ' + '(' + getActionParcelCount(actions.COURIER_TAKE_ORDERS) + ')'">
        <p-table
          [value]="getFilteredActions(actions.COURIER_TAKE_ORDERS)"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>AWB</th>
              <th>Referință unică</th>
              <th>Oră</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-action>
            <tr
              class="hover:surface-100 cursor-pointer"
              (click)="openOrderInfo(action)"
            >
              <td>{{ action.request.awb }}</td>
              <td>{{ action.request.uniqueReference }}</td>
              <td>
                {{ action.request.occurredAt | date: 'HH:mm' }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>
  } @else {
    <p-skeleton width="100%" height="3.625rem" borderRadius=".25rem" class="mt-4"></p-skeleton>
    <p-skeleton width="100%" height="3.625rem" borderRadius=".25rem" styleClass="mt-1"></p-skeleton>
    <p-skeleton width="100%" height="3.625rem" borderRadius=".25rem" styleClass="mt-1"></p-skeleton>
  }
</mat-dialog-content>