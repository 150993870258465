import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'app-cbox-profile-report-generate-controller',
  templateUrl: './cbox-profile-report-generate-controller.component.html',
  styleUrls: ["./cbox-profile-report-generate-controller.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    RadioButtonModule,
    ReactiveFormsModule
  ]
})

export class CBoxProfileReportGenerateControllerComponent implements OnInit {

  reportType = new FormControl<"lockers" | "agents" | "shipments" | "pickUpTimesActivity">("lockers");

  constructor() { }

  ngOnInit() { }
}