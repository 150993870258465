<p-toast></p-toast>
<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<div class="card">
  <p-table
    #dt
    [value]="failedEvents()"
    [selection]="selectedEvents()"
    dataKey="eventId"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    currentPageReportTemplate="{first} - {last} din {totalRecords} evenitmente"
    [globalFilterFields]="['eventId', 'procedureId', 'type', 'importance']"
    responsiveLayout="scroll"
    styleClass="p-datatable-sm"
    (selectionChange)="onSelectionChange($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center">
        <h5 class="m-0">Failed Events</h5>
        <div>
          @if (hasSelectedEvents()) {
          <button
            pButton
            [label]="'Forteaza procesarea' + (selectedCount() > 1 ? ' (' + selectedCount() + ')' : '')"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            (click)="confirmDeleteSelected()"
          ></button>
          }
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="eventId">
          Event ID <p-sortIcon field="eventId"></p-sortIcon>
        </th>
        <th>
          Procedure ID <p-sortIcon field="procedureId"></p-sortIcon>
        </th>
        <th pSortableColumn="type">
          Type <p-sortIcon field="type"></p-sortIcon>
        </th>
        <th pSortableColumn="importance">
          Importance <p-sortIcon field="importance"></p-sortIcon>
        </th>
        <th pSortableColumn="timestamp">
          Timestamp <p-sortIcon field="timestamp"></p-sortIcon>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-event>
      <tr [ngClass]="{'p-disabled': isEventMarkedForProcessing(event.eventId), 'warning-row': isEventMarkedForProcessing(event.eventId)}">
        <td>
          <p-tableCheckbox
            [disabled]="isEventMarkedForProcessing(event.eventId)"
            [value]="event"
          />
        </td>
        <td>{{ event.eventId }}</td>
        <td>{{ event.procedureId }}</td>
        <td>
          <p-tag
            [value]="event.type"
            [severity]="getTagSeverity(event.type)"
          ></p-tag>
        </td>
        <td>
          <p-tag
            [value]="event.importance"
            [severity]="getTagSeverity(event.importance)"
          ></p-tag>
        </td>
        <td>{{ event.timestamp | date : "medium" }}</td>
        <td>
          <button
            pButton
            [text]="true"
            icon="pi pi-eye"
            class="p-button mr-2"
            pTooltip="View Details"
            tooltipPosition="top"
            (click)="viewDetails(event)"
          ></button>
          @if (isEventMarkedForProcessing(event.eventId)) {
            <span class="text-yellow-600 font-italic">
              In curs de procesare...
            </span>
          }
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" class="text-center p-4">
          <div class="p-d-flex p-flex-column p-ai-center">
            <i
              class="pi pi-info-circle"
              style="font-size: 2rem; margin-bottom: 0.5rem"
            ></i>
            <span>Nu exista eventuri care au dat greş</span>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        In total sunt {{ totalEvents() }} evenimente care au dat greş.
        @if (selectedCount() > 0) {
          <div>{{ selectedCount() }} evenimente selectate</div>
        }
      </div>
    </ng-template>
  </p-table>
</div>

@if (showEventDetails()) {
<p-dialog
  [visible]="showEventDetailsValue()"
  (visibleChange)="showEventDetails.set($event)"
  header="Event Details"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [maximizable]="true"
>
  @if (currentEvent()) {
  <div class="grid">
    <div class="col-12 md:col-6">
      <h5>Event ID</h5>
      <p>{{ currentEvent()!.eventId }}</p>
    </div>
    <div class="col-12 md:col-6">
      <h5>Procedure ID</h5>
      <p style="overflow: hidden; text-overflow: ellipsis;">{{ currentEvent()!.procedureId }}</p>
    </div>
    <div class="col-12 md:col-6">
      <h5>Type</h5>
      <p-tag
        [value]="currentEvent()!.type"
        [severity]="getTagSeverity(currentEvent()!.importance)"
      ></p-tag>
    </div>
    <div class="col-12 md:col-6">
      <h5>Importance</h5>
      <p-tag
        [value]="currentEvent()!.importance"
        [severity]="getTagSeverity(currentEvent()!.importance)"
      ></p-tag>
    </div>
    <div class="col-12 md:col-6">
      <h5>Timestamp</h5>
      <p>{{ currentEvent()!.timestamp | date : "medium" }}</p>
    </div>
    <div class="col-12">
      <h5>Data</h5>
      <pre class="p-2 border-1 surface-border border-round surface-ground">{{
        currentEvent()!.data | json
      }}</pre>
    </div>
    @if (isEventMarkedForProcessing(currentEvent()!.eventId)) {
      <div class="col-12">
        <p-tag severity="warning" value="Currently Processing"></p-tag>
        <small class="block mt-1 text-yellow-700">This event is currently being processed</small>
      </div>
      }

  </div>
  }
  <ng-template pTemplate="footer">
    @if (currentEvent()) {
      <button
        pButton
        [label]="isEventMarkedForProcessing(currentEvent()!.eventId) ?
          'Este in curs de procesare' :  'Forteaza procesarea'"
        icon="pi pi-sync"
        [disabled]="isEventMarkedForProcessing(currentEvent()!.eventId)"
        (click)="processSingleEvent(currentEvent()!)"
        class="p-button-warning mr-2"
      ></button>
    }
    <button
      pButton
      label="Close"
      icon="pi pi-times"
      (click)="closeDetails()"
      class="p-button-text"
    ></button>
  </ng-template>
</p-dialog>
}