import { CommonModule } from "@angular/common";
import { Component, Injector, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { TableModule } from "primeng/table";
import { ProfileService } from "src/services/profile/profile.service";
import { MatDialog } from "@angular/material/dialog";
import { first } from "rxjs";
import { ProgressBarModule } from "primeng/progressbar";
import { MenuModule } from "primeng/menu";
import { CBox_GetLockerBaseData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CalendarModule } from "primeng/calendar";
import { MultiSelectModule } from "primeng/multiselect";
import { CBox_PublicGetAgentActivityDataListItem, CBox_PublicGetAgentActivityDataListParams, CBox_PublicGetLockerActivityDataListItem, CBox_PublicGetLockerActivityDataResponse } from "@server/services/cbox/public/api/v1/resources/reports/types";
import { lockerCleanAddress } from "src/helpers/functions";
import { CBoxProfilePickupRequestInfoComponent } from "app/profile/requests/pickup/dialogs/info/cbox-profile-pickup-request-info.component";
import { CBoxProfileLockersActivityReportGenerateDialogComponent } from "../dialogs/generate/lockers-activity/cbox-profile-lockers-activity-report-generate-dialog.component";
import { PaginatedTableWithFilters } from "app/shared/paginated-table-with-filters";

interface DailyTotals {
  [actionType: string]: number;
}

interface TotalsByDate {
  [formattedDate: string]: DailyTotals;
}

interface CountItem {
  type: string;
  count: number;
}

@Component({
  selector: "app-cbox-profile-lockers-reports-list",
  templateUrl: "cbox-profile-lockers-reports-list.component.html",
  styleUrls: ["./cbox-profile-lockers-reports-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    ProgressBarModule,
    MenuModule,
    CalendarModule,
    MultiSelectModule
  ]
})

export class CBoxProfileLockersReportsListComponent extends PaginatedTableWithFilters<CBox_PublicGetLockerActivityDataResponse, CBox_PublicGetAgentActivityDataListParams> {

  filtersForm: FormGroup;
  dataFetchUrl = "reports/lockers/activity";
  sessionFiltersKey = "profile/reports/lockers/filters";
  sessionFiltersToSave = ["page", "lockers", "interval"];
  localStorageFiltersKey = "profile/reports/lockers/filters";
  localStorageFiltersToSave = ["pageSize"];
  defaultFilters = signal({
    lockers: [],
    page: 1,
    pageSize: 25,
    interval: [this.oneMonthAgo, this.today]
  });
  actionTranslations = signal<Record<string, string>>({
    "USER_PICK_UP": "Ridicat de client",
    "USER_DROP_OFF": "Depus de client",
    "COURIER_PUT_ORDERS": "Depus de curier",
    "COURIER_TAKE_ORDERS": "Ridicat de curier",
    "COURIER_RETRIEVE_EXPIRED": "Recuperat de curier",
    "UNDEFINED": "Necunoscut"
  });
  expandedRows: Set<CBox_PublicGetLockerActivityDataListItem> = new Set();
  totalsByDates = signal<Record<string, DailyTotals>>({});

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private dialog: MatDialog) {
      super(inj);
      this.filtersForm = this.fb.group({
        page: [1],
        pageSize: [25],
        lockers: [[]],
        interval: [[this.oneMonthAgo, this.today]]
      });
    }

  openOrderInfo(request: {
    responseId: string;
    uniqueReference: string;
    awb: string;
  }): void {
    const dialog = this.dialog.open(CBoxProfilePickupRequestInfoComponent, {
      data: {
        responseId: request.responseId
      },
      minWidth: "min(1200px, 100%)",
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
    });
  }

  async init(): Promise<void> {
    this.profileService.setTitle("Rapoarte lockere");
  }

  override peekData(data: CBox_PublicGetLockerActivityDataResponse[]): void {
    const totalsByDates = data.reduce((acc: TotalsByDate, day: CBox_PublicGetLockerActivityDataResponse) => {
      const dayTotals: DailyTotals = {
        "COURIER_RETRIEVE_EXPIRED": 0,
        "COURIER_TAKE_ORDERS": 0,
        "COURIER_PUT_ORDERS": 0,
        "USER_PICK_UP": 0,
        "USER_DROP_OFF": 0
      };

      day.items.forEach(item => {
        if (item.actions && item.actions.counts) {
          item.actions.counts.forEach((countObj: CountItem) => {
            if (dayTotals.hasOwnProperty(countObj.type)) {
              dayTotals[countObj.type] += countObj.count;
            }
          });
        }
      });

      acc[day.date] = dayTotals;
      return acc;
    }, {} as TotalsByDate);

    this.totalsByDates.set(totalsByDates);
  }

  getActionCount(type: CBox_PublicGetAgentActivityDataListItem["actions"]["counts"][0]["type"], actions: CBox_PublicGetAgentActivityDataListItem["actions"]["counts"]): number {
    if (!actions) {
      return 0;
    }
    return actions.find((a: CBox_PublicGetAgentActivityDataListItem["actions"]["counts"][0]) => a.type === type)?.count ?? 0;
  }

  lockerCleanAddress(addressParts: CBox_GetLockerBaseData["addressParts"]): string {
    if (!addressParts) {
      return "";
    }
    return lockerCleanAddress(addressParts);
  }

  toggleRowExpansion(report: CBox_PublicGetLockerActivityDataListItem): void {
    if (this.expandedRows.has(report)) {
      this.expandedRows.delete(report);
    } else {
      this.expandedRows.add(report);
    }
  }

  isRowExpanded(report: CBox_PublicGetLockerActivityDataListItem): boolean {
    return this.expandedRows.has(report);
  }

  generateReport(): void {
    const dialog = this.dialog.open(CBoxProfileLockersActivityReportGenerateDialogComponent, {
      data: {
        interval: this.filtersForm.value.interval
      },
      minWidth: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
    });
  }

  getSearchStructure() {
    return Object.entries({
      "lockers[]": this.filtersForm.value.lockers,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize,
      startDate: this.filtersForm.value.interval[0]?.toISOString(),
      endDate: this.filtersForm.value.interval[1]?.toISOString(),
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_PublicGetAgentActivityDataListParams] = value;
      return acc;
    }, {} as any);
  }
}