<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Adaugă utilizator</h2>
  <p-button mat-dialog-close icon="pi pi-times" severity="secondary" [rounded]="true" [text]="true" />
</div>
<mat-dialog-content>
  @if (initializing()) {

  } @else {
    <form class="grid" [formGroup]="userFormGroup">
      @if (isCreating() === false) {
        @if (foundUser() === false) {
          <div class="col-12 py-6 text-center surface-100 border-round">
            <p class="mt-0">
              Introduceți identificatorul utilizatorului în câmpul de mai jos
            </p>
            <input
              pAutoFocus
              pInputText
              formControlName="identifier"
              [autofocus]="true"
            >
          </div>
        } @else {
          <div class="col-12 flex align-items-center justify-content-between surface-100 border-round p-3 mb-3">
            <div>
              <div class="flex align-items-center gap-1">
                <span class="pi pi-id-card"></span>
                @if (userData()?.identifier) {
                  <p class="m-0 text-xs">{{ userData()?.identifier }}</p>
                } @else {
                  <p class="m-0 text-xs">identificator negasit</p>
                }
              </div>
              <h3 class="text-lg m-0 mt-1">{{ userData()?.name }}</h3>
            </div>
            <p-button
              size="small"
              label="Modifică >"
              styleClass="p-2 surface-0 text-600 border-200 text-xs font-normal"
              (click)="reset()"
            ></p-button>
          </div>

          @if (!!userFormGroup.get("noEmailAddress")?.value) {
            <p-messages
              styleClass="text-xs"
              class="col-12"
              [value]="noEmailAddressWarningMessage()"
              [enableService]="false"
              [closable]="false"
            />
          }

          @if (!userData()?.identifier) {
            <div class="col-12 sm:col-6">
              <span class="block font-bold mb-1">Nume</span>
              <input autocomplete="off" class="w-full" type="text" pInputText formControlName="name">
            </div>

            <div class="col-12 sm:col-6">
              <span class="block font-bold mb-1">Identificator</span>
              <input autocomplete="off" class="w-full" type="text" pInputText formControlName="identifier">
            </div>
          }

          <div class="col-12 sm:col-6">
            <span class="font-bold block mb-1">Email</span>
            @let email = userFormGroup.get("email");
            <input
              class="w-full"
              type="text"
              pInputText
              [placeholder]="email?.disabled ? '' : 'test@companie.ro'"
              formControlName="email"
            >
            @let emailErrors = userFormGroup.get("email")?.errors;
            @if (email?.dirty) {
              @if (emailErrors) {
                @if (emailErrors?.["required"]) {
                  <small class="text-red-500">Email-ul este obligatoriu!</small>
                }
              }
            }
            <div class="flex align-items-center gap-2">
              <p-checkbox
                class="mt-1"
                [binary]="true"
                formControlName="noEmailAddress"
                inputId="noEmailAddress"
                (onChange)="noEmailAddressToggled(); email?.reset();"
              ></p-checkbox>
              <label class="-mb-1 text-xs" for="noEmailAddress">Utilizatorul nu are adresă de email</label>
            </div>
          </div>

          <div
            class="col-12 sm:col-6"
          >
            <span class="font-bold block mb-1">Telefon</span>
            @let phone = userFormGroup.get("phone");
            <input
              class="w-full"
              type="tel"
              pKeyFilter="int"
              pInputText
              [placeholder]="phone?.disabled ? '' : '07xxxxxxxx'"
              formControlName="phone"
            />
            @let phoneErrors = userFormGroup.get("phone")?.errors;
            @if (phone?.dirty) {
              @if (phoneErrors) {
                @if (phoneErrors?.["required"]) {
                  <small class="text-red-500">Numarul de telefon este obligatoriu!</small>
                }
              }
            }
            <div class="flex align-items-center gap-2">
              <p-checkbox
                class="mt-1"
                [binary]="true"
                formControlName="noPhoneNumber"
                inputId="noPhoneNumber"
                (onChange)="noPhoneNumberToggled(); phone?.reset();"
              ></p-checkbox>
              <label class="-mb-1 text-xs" for="noPhoneNumber">Utilizatorul nu are număr de telefon</label>
            </div>
          </div>

          <div class="col-12">
            <span class="block font-bold mb-1">Rol</span>
            <p-dropdown optionLabel="roleName" optionValue="roleName" styleClass="w-full" placeholder="Selectati un rol"
              formControlName="role" [options]="availableRoles()" [loading]="!availableRoles()"></p-dropdown>
          </div>

          <div class="col-12">
            <span class="font-bold block mb-1">Zonă</span>
            <p-dropdown formControlName="zoneId" optionLabel="name" optionValue="id" placeholder="Selectează o zonă"
              styleClass="w-full" appendTo="body" filterBy="name" [filter]="true" [options]="availableZones()"
              [loading]="!zonesLoaded()" />
            @let zone = userFormGroup.get("zoneId");
            @let zoneErrors = zone?.errors;
            @if (zone?.dirty && zone?.touched) {
              @if (zoneErrors?.["minlength"]) {
                <small class="text-red-500">Vă rugăm să selectați zona de acces</small>
              }
            }
          </div>

          @if (!isAgentSearchMandatory() || userData()?.identifier) {
            <p-messages
              styleClass="text-xs m-0"
              class="col-12"
              severity="info"
            >
                <ng-template pTemplate>
                  <div class="flex align-items-center gap-2">
                    <div class="flex-column">
                      <div>În cazul în care doriți că acest utilizator să aibă acces la lockere bifați căsuță de mai jos</div>
                      <div class="flex align-items-center gap-2">
                        <p-checkbox class="mt-1" [binary]="true" formControlName="shouldCreateAgent"
                          inputId="shouldCreateAgent"></p-checkbox>
                        <label class="-mb-1 text-xs" for="shouldCreateAgent">Utilizatorul este si agent</label>
                      </div>
                    </div>
                  </div>
                </ng-template>
            </p-messages>
          }

          <div class="col-12">
            <span class="font-bold block mb-1">Notițe</span>
            <textarea class="w-full" rows="5" cols="30" pInputTextarea formControlName="notes"></textarea>
          </div>
        }
      } @else {
        <div class="col-12 py-4">
          <div class="flex align-items-center my-2">
            @if (userCreateStatus() === "unknown") {
              <p-progressSpinner styleClass="w-2rem h-2rem"></p-progressSpinner>
              <p class="m-0 ml-2 text-lg">Se creează utilizatorul...</p>
            }
            @else if (userCreateStatus() === "success") {
              <span class="pi pi-check-circle text-green-500 text-lg"></span>
              <p class="m-0 ml-2 text-lg">Utilizatorul a fost creat cu succes!</p>
            } @else if (userCreateStatus() === "error") {
              <div class="flex align-items-center">
                <span class="pi pi-times-circle text-red-500 text-lg"></span>
                <p class="m-0 ml-2 text-lg">Eroare la crearea utilizatorului</p>
              </div>
              @if (userSaveRetryAllowed()) {
                <p-button
                  size="small"
                  styleClass="px-2 py-1 text-xs ml-3"
                  label="Reîncearcă"
                  [link]="true"
                  (click)="retryUserSave()"
                ></p-button>
              }
            }
          </div>

          @if (userSaveRetryAllowed() && !!this.userFormGroup.get("shouldCreateAgent")?.value) {
            @if (userCreateStatus() === "success") {
              <div class="flex align-items-center my-2">
                @if (agentCreateStatus() === "unknown") {
                  <p-progressSpinner styleClass="w-2rem h-2rem"></p-progressSpinner>
                  <p class="m-0 ml-2 text-lg">Se creează agentul...</p>
                }
                @else if (agentCreateStatus() === "success") {
                  <span class="pi pi-check-circle text-green-500 text-lg"></span>
                  <p class="m-0 ml-2 text-lg">Agentul a fost creat cu succes!</p>
                } @else if (agentCreateStatus() === "error") {
                    <span class="pi pi-times-circle text-red-500 text-lg"></span>
                    <p class="m-0 ml-2 text-lg">Eroare la crearea agentului</p>
                  @if (agentSaveRetryAllowed()) {
                    <p-button
                      size="small"
                      styleClass="px-2 py-1 text-xs ml-3"
                      label="Reîncearcă"
                      [link]="true"
                      (click)="retryUserSave()"
                    ></p-button>
                  }
                }
              </div>
            }
          }
        </div>
      }
    </form>
  }
</mat-dialog-content>


@if (!isCreating()) {
  <mat-dialog-actions align="end">
    @if (foundUser() === false) {
      <button
        pButton
        size="small"
        label="Caută utilizator"
        icon="pi pi-search"
        iconPos="left"
        [loading]="isSearching()"
        (click)="searchUser()"
      ></button>
    } @else {
      <button
        pButton
        size="small"
        label="Adaugă"
        [disabled]="!userFormGroup.valid"
        (click)="onSaveUser()"
      ></button>
    }
  </mat-dialog-actions>
} @else {
  <mat-dialog-actions>
    <button
      pButton
      size="small"
      label="Închide"
      mat-dialog-close
    ></button>
  </mat-dialog-actions>
}

@if (loading()) {
  <div class="blurred-div">
    <p-progressSpinner
      ariaLabel="loading"
      class="blurred-div-spinner"
    ></p-progressSpinner>
  </div>
}
