<form class="grid align-items-center mt-1" [formGroup]="filtersForm">
  <div class="col-12 sm:col-3">
    <span class="font-bold block mb-1">Lockere</span>
    <p-multiSelect
        formControlName="id"
        optionLabel="name"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
        placeholder="Caută lockere"
        filterBy="id,name"
        [showClear]="true"
        [options]="lockers()"
        [loading]="!lockers()"
      >
        <ng-template let-locker pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>
              <small class="text-gray-400">#{{ locker?.id }}</small>
              <p class="m-0">{{ locker?.name }}</p>
            </div>
          </div>
        </ng-template>
      </p-multiSelect>
  </div>

  <div class="col-12 sm:col-3">
    <span class="font-bold block mb-1">Tip procedură</span>
    <p-multiSelect
      formControlName="type"
      optionLabel="label"
      optionValue="value"
      styleClass="w-full"
      appendTo="body"
      [options]="procedureTypes()"
    ></p-multiSelect>
  </div>
</form>
<p-table
  styleClass="p-datatable-sm w-full"
  paginatorPosition="both"
  currentPageReportTemplate="{first} - {last} din {totalRecords} proceduri"
  dataKey="uniqueProcedureId"
  [first]="(filtersForm.value.page - 1) * filtersForm.value.pageSize"
  [expandedRowKeys]="expandedRows()"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [value]="data()"
  [rows]="filtersForm.get('pageSize')?.value"
  [paginator]="true"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[25, 50, 100]"
  (onPage)="pageChanged($event)"
  (onRowExpand)="expandRow($event)"
  (onRowCollapse)="collapseRow($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr>
        <td colspan="7">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th></th>
      <th>Locker</th>
      <th>Dată</th>
      <th>Tip</th>
      <th>Numar evenimente</th>
      <th>Unique ID</th>
      <th>Trigger</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-procedure let-expanded="expanded">
    <tr [ngClass]="{
      'bg-yellow-50': procedure.isTagged
    }">
      <td class="py-0">
        <p-button
          pRipple
          type="button"
          size="small"
          [pRowToggler]="procedure"
          [text]="true"
          [rounded]="true"
          [plain]="true"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        />
      </td>
      <td>
        <div class="flex flex-column">
          <div>
            <small class="text-gray-400">#{{ procedure.locker.identifier }}</small><br>
            <b>{{ procedure.locker.name }}</b>
          </div>
        </div>
      </td>
      <td>
        {{ procedure.createdAt | date: "dd MMMM yyyy HH:mm:ss:SSS" }}
      </td>
      <td>{{ procedure.procedureType }}</td>
      <td>{{ procedure.eventCount }}</td>
      <td>{{ procedure.uniqueProcedureId }}</td>
      <td>
        @let procedureLink = procedure?.procedureLink;
        @switch (procedureLink?.linkType) {
          @case ("AGENT") {
            @let procedureLinkAgent = procedureLink?.agent;
            <p class="text-xs text-gray-400 m-0">Agent {{ procedureLink.company?.name }}</p>
            <p class="text-xs text-gray-400 m-0">{{ procedureLinkAgent.identifier }}</p>
            <b class="text-lg">{{ procedureLinkAgent?.name }}</b>
            <p class="text-xs m-0">
              {{ procedureLinkAgent?.phone }}
              @if (procedureLinkAgent?.email) {
                <span>
                  &#x2022;
                  {{ procedureLinkAgent?.email }}
                </span>
              }
            </p>
          }

          @case ("REQUEST") {
            <div>
              <p class="text-xs text-gray-400 m-0">Comanda {{ procedureLink?.company.name }}</p>
              <div class="cursor-pointer" (click)="openOrderInfo(procedureLink.requestType, procedureLink.responseId)">
                <b class="text-lg">{{ procedureLink?.awb }}</b>
                <span class="pi pi-external-link mx-1"></span>
              </div>
            </div>
          }
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-procedure>
    @if (!proceduresData()[procedure.uniqueProcedureId]) {
      <tr>
        <td colspan="7">
          <div class="p-3">
            <p-table styleClass="p-datatable-sm" [value]="[1, 2, 3]" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </th>
                  <th>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </th>
                  <th>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </th>
                  <th>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-event>
                <tr>
                  <td>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </td>
                  <td>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </td>
                  <td>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </td>
                  <td>
                    <p-skeleton width="10rem" borderRadius="100vw"></p-skeleton>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6">Nu există evenimente.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    } @else {
      <tr>
        <td colspan="7">
          <div class="p-3">
            <p-table styleClass="p-datatable-sm" [value]="proceduresData()[procedure.uniqueProcedureId]!.events" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>Tip</th>
                  <th>Subtip</th>
                  <th>Dată și oră</th>
                  <th>ID eveniment</th>
                  <th>Raportat de</th>
                  <th>Usa</th>
                  <th>Comanda</th>
                  <th>Importanță</th>
                  <th>Raw data</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-event>
                <tr>
                  <td>{{ event.type }}</td>
                  <td>{{ event?.rawData?.subProcedureType }}</td>
                  <td>{{ event?.occurredAt | date: "dd MMMM YYYY HH:mm:ss:SSS" }}</td>
                  <td>{{ event.eventId }}</td>
                  <td>{{ event?.rawData?.reportedBy }}</td>
                  <td>
                    @let eventBox = event?.box;
                    @if (eventBox) {
                      <p class="text-xs text-gray-400 m-0">ID {{ eventBox?.uniqueBoxId }} &#x2022; Modul {{ eventBox?.modulePosition }}</p>
                      <b class="m-0">Coloana {{ eventBox?.position.column }} linia {{ eventBox?.position.row }} ({{ eventBox?.typeName }})</b>
                    }
                  </td>
                  <td>
                    @if (event?.request) {
                      <p class="text-xs text-gray-400 m-0">Comanda {{ event?.request?.company.name }}</p>
                      <div class="cursor-pointer" (click)="openOrderInfo(event.request.requestType, event.request.id)">
                        <b class="text-lg">{{ event?.request?.awb }}</b>
                        <span class="pi pi-external-link mx-1"></span>
                      </div>
                    }
                  </td>
                  <td>{{ event.importance }}</td>
                  <td>{{ event.rawData | json }}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6">Nu există evenimente.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage">
    @if (!loading()) {
      <tr>
        <td colspan="8">
          <p class="text-gray-400 text-center text-lg font-bold">Nu există proceduri.</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>