<p-toast
  [baseZIndex]="9999"
  position="bottom-center"
  key="global-toast"
></p-toast>

<p-toast
  [baseZIndex]="9999"
  position="top-right"
  key="sticky-toast"
>
  <ng-template let-message pTemplate="message">
    @let report = message.data;
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="font-medium text-lg my-1 text-900">
          {{ message.summary }}
      </div>
      <p class="m-0 mb-1">{{ message.detail }}</p>
      <div class="flex align-items-center gap-2">
        @if (reportHasFileType(report, reportFileTypes.PDF)) {
          @let pdfDownloading = reportsService.isDownloadingReport(report, reportFileTypes.PDF);
          @if (getReportFileTypeCount(report, reportFileTypes.PDF) > 1) {
            <button
              pButton
              class="text-xs p-2"
              size="small"
              [loading]="pdfDownloading"
              (click)="pdfMenu.toggle($event)"
            >
              <div class="flex align-items-center gap-2">
                @if (!pdfDownloading) {
                  <span class="pi pi-file-pdf"></span>
                }
                <span [ngClass]="{
                  'ml-2': pdfDownloading
                }" class="text-xs font-bold">PDF</span>
                <span class="pi pi-chevron-down text-xs"></span>
              </div>
            </button>
            <p-menu
              #pdfMenu
              styleClass="w-15rem"
              appendTo="body"
              [tabindex]="undefined"
              [popup]="true"
              [model]="getReportMenuItems(report, reportFileTypes.PDF)"
            ></p-menu>
          } @else {
            <button
              pButton
              class="text-xs p-2"
              size="small"
              [loading]="pdfDownloading"
              (click)="downloadReport(report, reportFileTypes.PDF, 'default')"
            >
              <div class="flex align-items-center gap-2">
                @if (!pdfDownloading) {
                  <span class="pi pi-file-pdf"></span>
                }
                <span [ngClass]="{
                  'ml-2': pdfDownloading
                }" class="text-xs font-bold">PDF</span>
              </div>
            </button>
          }
        }

        @if (reportHasFileType(report, reportFileTypes.XLSX)) {
          @let xlsxDownloading = reportsService.isDownloadingReport(report, reportFileTypes.XLSX);
          @if (getReportFileTypeCount(report, reportFileTypes.XLSX) > 1) {
            <button
              pButton
              class="text-xs p-2"
              size="small"
              [loading]="xlsxDownloading"
              (click)="xlsxMenu.toggle($event)"
            >
              <div class="flex align-items-center gap-2">
                @if (!xlsxDownloading) {
                  <span class="pi pi-file-excel"></span>
                }
                <span [ngClass]="{
                  'ml-2': xlsxDownloading
                }" class="text-xs font-bold">XLSX</span>
                <span class="pi pi-chevron-down text-xs"></span>
              </div>
            </button>
            <p-menu
              #xlsxMenu
              styleClass="w-15rem"
              appendTo="body"
              [tabindex]="undefined"
              [popup]="true"
              [model]="getReportMenuItems(report, reportFileTypes.XLSX)"
            ></p-menu>
          } @else {
            <button
              pButton
              class="text-xs p-2"
              size="small"
              [loading]="xlsxDownloading"
              (click)="downloadReport(report, reportFileTypes.XLSX, 'default')"
            >
              <div class="flex align-items-center gap-2">
                @if (!xlsxDownloading) {
                  <span class="pi pi-file-excel"></span>
                }
                <span [ngClass]="{
                  'ml-2': xlsxDownloading
                }" class="text-xs font-bold">XLSX</span>
              </div>
            </button>
          }
        }
      </div>
    </div>
  </ng-template>
</p-toast>
<router-outlet></router-outlet>