<form [formGroup]="filtersForm" class="grid align-items-end mt-2">
  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-2">Interval cautare</span>
    <p-calendar
      inputId="interval"
      styleClass="w-full"
      dateFormat="dd/mm/yy"
      selectionMode="range"
      formControlName="interval"
      [showIcon]="true"
      [showOnFocus]="true"
      [placeholder]="'Data de la'"
      [panelStyle]="{
        'height': '439px'
      }"
    ></p-calendar>
  </div>

  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Tip</span>
    <p-multiSelect
      styleClass="w-full"
      optionLabel="label"
      optionValue="type"
      formControlName="types"
      [options]="reportTypes()"
    ></p-multiSelect>
  </div>

  <div class="
    col-12 flex-order-0
    lg:col-offset-3 lg:flex-order-1 lg:col-3
    text-right
  ">
    <p-button
      label="Generează raport"
      icon="pi pi-file-plus"
      (onClick)="generateReport()"
    ></p-button>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm w-full"
  currentPageReportTemplate="{first} - {last} din {totalRecords} rapoarte"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="data()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr>
        <td colspan="8">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th>Tip raport</th>
      <th>Criterii</th>
      <th>Perioada</th>
      <th>Status</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-report>
    <tr>
      <td>{{ reportTypeTranslations()[report.type] }}</td>
      <td>
        @if (report.lockers.length) {
          <div class="flex align-items-center gap-1 cursor-pointer"
            (click)="patchLockerFilterOptions(report.lockers); lockersPanel.toggle($event)">
            @let isLockerSingular = report.lockers.length === 1;
            {{ report.lockers.length }} {{ isLockerSingular ? "locker" : "lockere" }}
            <span class="material-symbols-rounded">keyboard_arrow_down</span>
          </div>
        }
        @if (report.agents.length) {
          <div class="flex align-items-center gap-1 cursor-pointer"
            (click)="patchAgentFilterOptions(report.agents); agentsPanel.toggle($event)">
            @let isAgentSingular = report.agents.length === 1;
            {{ report.agents.length }} {{ isAgentSingular ? "agent" : "agenți" }}
            <span class="material-symbols-rounded">keyboard_arrow_down</span>
          </div>
        }
        <p-overlayPanel #lockersPanel>
          <div class="flex flex-column w-20rem">
            @if (overlayPanelLockersFilterOptions().length > 5) {
              <div div class="surface-overlay pb-4">
                <input
                  pInputText
                  class="w-full"
                  placeholder="Filtrează lockere"
                  [formControl]="overlayPanelSearchFilter"
                >
              </div>
            }
            <div class="max-h-15rem overflow-auto">
              @for (locker of filteredOverlayPanelLockersOptions(); track $index) {
                <div class="flex justifty-content-between align-items-center w-full pt-2" [ngClass]="{
                            'border-top-1': $index !== 0,
                            'border-gray-100': $index !== 0
                          }">
                  <div>
                    <p class="text-xs text-gray-400 m-0">#{{ locker.id }}</p>
                    <b class="m-0">{{ locker.name }}</b>
                  </div>
                  <p-button class="ml-auto" size="small" icon="pi pi-info-circle" [text]="true"
                    (onClick)="lockersPanel.toggle($event); openLockerDetails(locker.name)"></p-button>
                </div>
              } @empty {
                <p class="text-center text-gray-400 pt-2">Căutarea nu a returnat niciun rezultat</p>
              }
            </div>
          </div>
        </p-overlayPanel>

        <p-overlayPanel #agentsPanel>
          <div class="flex flex-column w-20rem">
            @if (overlayPanelAgentsFilterOptions().length > 5) {
              <div class="surface-overlay pb-4">
                <input
                  pInputText
                  class="w-full"
                  placeholder="Filtrează agenții"
                  [formControl]="overlayPanelSearchFilter"
                >
              </div>
            }
            <div class="max-h-15rem overflow-auto">
              @for (agent of filteredOverlayPanelAgentsOptions(); track $index) {
                <div class="flex justifty-content-between align-items-center w-full pt-2" [ngClass]="{
                            'border-top-1': $index !== 0,
                            'border-gray-100': $index !== 0
                          }">
                  <div>
                    <p class="text-xs text-gray-400 m-0">{{ agent.identifier }}</p>
                    <b class="m-0">{{ agent.name }}</b>
                  </div>
                </div>
              } @empty {
                <p class="text-center text-gray-400 pt-2">Căutarea nu a returnat niciun rezultat</p>
              }
            </div>
          </div>
        </p-overlayPanel>
      </td>
      <!-- <td>{{ report.createdAt | date: "dd MMMM yyyy" }}</td> -->
      <td>{{ report.interval.start | date: "dd MMMM yyyy HH:mm" }} - {{ report.interval.end | date: "dd MMMM yyyy HH:mm" }}</td>
      <td>
        <p-tag
          styleClass="text-xs"
          [severity]="getReportSeverity(report.state)"
        >
          <div class="flex align-items-center gap-2">
            <div class="border-round bg-white" style="width: .5rem; height: .5rem;"></div>
            <span>{{ reportStatesTranslations()[report.state] }}</span>
          </div>
        </p-tag>
      </td>
      <td class="text-right p-0">
        @if (report.state === "IN_PROGRESS" || report.state === "PENDING") {
          <div class="card flex justify-content-end" [style]="{
            'padding-block': '.4375rem'
          }">
            <p-progressSpinner
              class="mx-2"
              strokeWidth="4"
              [style]="{
                width: '1.5rem',
                height: '1.5rem',
              }"
            ></p-progressSpinner>
          </div>
        } @else {
          <div class="card flex gap-2 align-items-center justify-content-end py-1">
            <!-- PDF Button -->
            @if (getReportFileTypeCount(report, reportFileTypes.PDF) > 0) {
              @let pdfDownloading = isDownloading(report, reportFileTypes.PDF);
              @if (getReportFileTypeCount(report, reportFileTypes.PDF) > 1) {
                <button
                  #pdfButton
                  pButton
                  class="p-2 text-xs download-button"
                  type="button"
                  [loading]="pdfDownloading"
                  (click)="reportMenu.toggle($event)"
                >
                  <div class="flex align-items-center justify-content-start gap-2">
                    @if (!pdfDownloading) {
                      <span class="pi pi-file-pdf"></span>
                    }
                    <span [ngClass]="{
                      'ml-2': pdfDownloading
                    }" class="text-xs font-bold">PDF</span>
                    <span class="pi pi-chevron-down text-xs"></span>
                  </div>
                </button>
              } @else {
                <button
                  pButton
                  class="p-2 text-xs download-button"
                  type="button"
                  [loading]="pdfDownloading"
                  (click)="downloadReport(report, reportFileTypes.PDF, 'default')"
                >
                  <div class="flex align-items-center justify-content-start gap-2">
                    @if (!pdfDownloading) {
                      <span class="pi pi-file-pdf"></span>
                    }
                    <span [ngClass]="{
                      'ml-2': pdfDownloading
                    }" class="text-xs font-bold">PDF</span>
                  </div>
                </button>
              }
            }

            <!-- XLSX Button -->
            @if (getReportFileTypeCount(report, reportFileTypes.XLSX) > 0) {
              @let xlsxDownloading = isDownloading(report, reportFileTypes.XLSX);
              @if (getReportFileTypeCount(report, reportFileTypes.XLSX) > 1) {
                <button
                  pButton
                  class="p-2 text-xs download-button"
                  type="button"
                  [loading]="xlsxDownloading"
                  (click)="reportMenu.toggle($event)"
                >
                  <div class="flex align-items-center gap-2">
                    @if (!xlsxDownloading) {
                      <span class="pi pi-file-excel"></span>
                    }
                    <span [ngClass]="{
                      'ml-2': xlsxDownloading
                    }" class="text-xs font-bold">XLSX</span>
                    <span class="pi pi-chevron-down text-xs"></span>
                  </div>
                </button>
              } @else {
                <button
                  pButton
                  class="p-2 text-xs download-button"
                  type="button"
                  [loading]="xlsxDownloading"
                  (click)="downloadReport(report, reportFileTypes.XLSX, 'default')"
                >
                  <div class="flex align-items-center gap-2">
                    @if (!xlsxDownloading) {
                      <span class="pi pi-file-excel"></span>
                    }
                    <span [ngClass]="{
                      'ml-2': xlsxDownloading
                    }" class="text-xs font-bold">XLSX</span>
                  </div>
                </button>
              }
            }
          </div>
          <p-menu
            #reportMenu
            styleClass="w-15rem"
            appendTo="body"
            [tabindex]="undefined"
            [popup]="true"
            [model]="getReportMenuItems(report, reportFileTypes.PDF)"
          />
          <p-menu
            #reportMenu
            styleClass="w-15rem"
            appendTo="body"
            [tabindex]="undefined"
            [popup]="true"
            [model]="getReportMenuItems(report, reportFileTypes.XLSX)"
          />
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    @if (!loading()) {
      <tr>
        <td colspan="8">
          <p class="text-gray-400 text-center text-lg font-bold">Nu exista rapoarte.</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>