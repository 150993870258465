import { Component, Input, OnInit, signal, computed, inject, ChangeDetectionStrategy, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// PrimeNG imports
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { CBox_EventImportanceLevel, CBox_EventProceduresType, CBox_ProcedureEvent } from '@server/services/cbox/internal/api/v1/event/event_types';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/services/toast/toast.service';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';

@Component({
  selector: 'app-failed-events-table',
  templateUrl: "./cbox-admin-failed-events-table.component.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    CheckboxModule,
    ButtonModule,
    TooltipModule,
    TagModule,
    ConfirmDialogModule,
    ToastModule,
    DialogModule
  ],
  providers: [
    ConfirmationService,
  ]
})
export class FailedEventsTableComponent {
  private confirmationService = inject(ConfirmationService);
  private toastService = inject(ToastService);
  private api = inject(ApiService);

  failedEvents = input.required<CBox_ProcedureEvent[]>();
  lockerIdentifier = input.required<string>();
  markedForProcessing = input.required<string[]>();

  dirty = output<void>();

  selectedEvents = signal<CBox_ProcedureEvent[]>([]);
  currentEvent = signal<CBox_ProcedureEvent | null>(null);
  showEventDetails = signal<boolean>(false);

  totalEvents = computed(() => this.failedEvents().length);
  selectedCount = computed(() => this.selectedEvents().length);
  hasSelectedEvents = computed(() => this.selectedCount() > 0);

  showEventDetailsValue = computed(() => this.showEventDetails());

  onSelectionChange(events: CBox_ProcedureEvent[]) {
    this.selectedEvents.set(events);
  }

  getTagSeverity(level: CBox_EventImportanceLevel) {
    switch (level) {
      case CBox_EventImportanceLevel.LOW: return 'success';
      case CBox_EventImportanceLevel.MEDIUM: return 'warning';
      case CBox_EventImportanceLevel.HIGH: return 'danger';
      case CBox_EventImportanceLevel.CRITICAL: return 'danger';
      default: return 'info';
    }
  }

  viewDetails(event: CBox_ProcedureEvent) {
    this.currentEvent.set(event);
    this.showEventDetails.set(true);
  }

  closeDetails() {
    this.showEventDetails.set(false);
  }

  confirmDeleteSelected() {
    this.confirmationService.confirm({
      message: `Esti sigur ca vrei sa fortezi procesarea a ${this.selectedCount()} eventuri?`,
      header: 'Confirmare',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.processEvents(this.selectedEvents().map(e => e.eventId));
      }
    });
  }

  processSingleEvent(event: CBox_ProcedureEvent) {
    this.confirmationService.confirm({
      message: `Esti sigur ca vrei sa fortezi procesarea acestui event?`,
      header: 'Confirmare',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
       this.processEvents([event.eventId]);
      }
    });
  }

  private processEvents(eventsIds: string[]) {
    this.api.patch<CBox_PublicSuccessResponse<void>>("backend/internal/locker/events/force-process", {
      lockerIdentifier: this.lockerIdentifier(),
      eventIds: eventsIds ?? []
    }).subscribe({
        next: (res) => {
          this.toastService.showSuccessToast(
            "Confirmare.",
            "Eventurile selectate au fost procesate cu succes.",
          );
          this.dirty.emit();
        },
        error: (err: HttpErrorResponse) => {
          handlePublicApiError(err, this.toastService);
        }
      });
  }

  isEventMarkedForProcessing(eventId: string): boolean {
    if (!this.markedForProcessing() || !Array.isArray(this.markedForProcessing())) {
      return false;
    }

    // Use some instead of includes for more robustness
    return this.markedForProcessing().some(id => id === eventId);
  }
}
