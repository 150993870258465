import { CommonModule, formatDate } from "@angular/common";
import { Component, Inject, OnInit, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_AdminLockerModuleDataResponse, CBox_AdminModuleListItemDataResponse } from "@server/services/cbox/public/api/v1/resources/internal/locker_module/types";
import { CBox_AdminGetTodoListDataItem, CBox_AdminGetTodoListDataParams } from "@server/services/cbox/public/api/v1/resources/internal/todo/types";
import { CBoxAdminEquipmentCreateDialogComponent } from "app/profile/admin/components/dialogs/create/cbox-admin-equipment-create.component";
import { CBoxAdminModuleInfoComponent } from "app/profile/admin/modules/dialogs/info/cbox-admin-module-info.component";
import { MenuItem } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SkeletonModule } from "primeng/skeleton";
import { TableModule } from "primeng/table";
import { first } from "rxjs";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { CBoxToDoListComponent } from "../../../../../shared/to-do/list/cbox-to-do-list.component";
import { CBoxToDoCreateDialogComponent } from "app/shared/to-do/create/cbox-to-do-create-dialog.component";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ToastService } from "src/services/toast/toast.service";
import { CBox_AdminLockerData } from "@server/services/cbox/public/api/v1/resources/internal/locker/types";
import { CBoxAdminLockerConfigDialogComponent } from "../config/cbox-admin-locker-config-dialog.component";
import { formatTimeInMilliseconds, hasMinutesPassedSince, lockerCleanAddress } from "src/helpers/functions";
import { CBoxProfileLockerUpdateConfirmationDialogComponent } from "../update-confirmation/cbox-profile-locker-update-confirmation-dialog.component";
import { MeterGroupModule, MeterItem } from "primeng/metergroup";
import { TagModule } from "primeng/tag";
import { FailedEventsTableComponent } from "./failed-events/cbox-admin-failed-events-table.component";

type ModuleEquipmentType = CBox_AdminLockerModuleDataResponse["equipments"][number] & {
  module: {
    serial: string;
    id: number;
  };
};

@Component({
  selector: "app-cbox-admin-locker-info",
  templateUrl: "./cbox-admin-locker-info.component.html",
  styleUrls: ["./cbox-admin-locker-info.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogContent,
    MatDialogClose,
    ProgressSpinnerModule,
    TableModule,
    FailedEventsTableComponent,
    SkeletonModule,
    CBoxToDoListComponent,
    ProgressSpinnerModule,
    MeterGroupModule,
    TagModule
  ]
})

export class CBoxAdminLockerInfoComponent implements OnInit {

  modules = signal<CBox_AdminModuleListItemDataResponse[]>([]);
  modulesData = signal<Record<number, CBox_AdminLockerModuleDataResponse | undefined>>({});
  equipments = signal<ModuleEquipmentType[]>([]);
  equipmentsReady = signal(false);
  lockerData = signal<CBox_AdminLockerData | undefined>(undefined);
  lockerExtras = signal<{
    isOldHeartbeat: boolean;
    memoryFreePercentage: number;
    storageFreePercentage: number
  } | undefined>(undefined);
  dataFetched = signal<Record<string, boolean>>({
    todos: false,
    modules: false,
    lockerData: false
  });
  loading = signal(true);
  memoryMeterItems = signal<MeterItem[]>([]);
  storageMeterItems = signal<MeterItem[]>([]);

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxAdminLockerInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public lockerIdentifier: string) { }

  ngOnInit(): void {
    this.init();
  }

  getModuleNecessaryEquipmentsCount(moduleId: number): number {
    return this.modulesData()[moduleId]?.equipmentTypeCounts.reduce((acc, equipmentType) => {
      if (equipmentType.counts.actual >= equipmentType.counts.minRequired) {
        return acc;
      }

      return acc + equipmentType.counts.minRequired;
    }, 0) || 0;
  }

  openModuleDataDialog(moduleId: number): void {
    const dialog = this.dialog.open(CBoxAdminModuleInfoComponent, {
      data: moduleId
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchModuleData(moduleId);
    });
  }

  copyAndCreateEquipment(equipment?: ModuleEquipmentType): void {
    if (!equipment) {
      return;
    }

    const dialog = this.dialog.open(CBoxAdminEquipmentCreateDialogComponent, {
      data: equipment,
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.equipmentsReady.set(false);
      this.fetchModuleData(equipment.module.id);
    });
  }

  getEquipmentMenuItems(equipment: ModuleEquipmentType): MenuItem[] {
    return [
      {
        label: "Creează echipament similar",
        icon: "pi pi-plus",
        command: () => {
          this.copyAndCreateEquipment(equipment);
        },
      },
    ];
  }

  createEquipmentToDo(equipment: any): void {
    const dialog = this.dialog.open(CBoxToDoCreateDialogComponent, {
      data: {
        equipment: {
          id: equipment.id,
          typeName: equipment.type.name
        }
      },
      width: "min(600px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchTodos();
    });
  }

  configLocker(): void {
    const dialog = this.dialog.open(CBoxAdminLockerConfigDialogComponent, {
      data: this.lockerData(),
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.dataFetched.update(fetched => {
        fetched["lockerData"] = false;
        return fetched;
      });
      this.fetchLockerData();
    });
  }

  lockerCleanAddress(): string {
    return lockerCleanAddress(this.lockerData()?.addressParts!) || "";
  }

  updateLocker(): void {
    const confirmation = this.dialog.open(CBoxProfileLockerUpdateConfirmationDialogComponent, {
      data: this.lockerIdentifier
    });

    confirmation.afterClosed().pipe(first()).subscribe((confirmed) => {
      if (confirmed) {
        this.performLockerUpdate();
      }
    });
  }

  formatTimeInMilliseconds(ms: number | undefined): string {
    if (!ms) {
      return "0";
    }
    return formatTimeInMilliseconds(ms);
  }

  formatDate(date: Date | undefined): string {
    if (!date) {
      return "";
    }
    return formatDate(date, "dd-MM-YYYY HH:mm", "ro-RO", "Europe/Bucharest");
  }

  private performLockerUpdate(): void {
    this.api.put("backend/internal/locker/update", { lockerIdentifier: this.lockerIdentifier }).subscribe((response) => {
      this.toastService.showSuccessToast("Confirmare", "Locker-ul este in proces de actualizare.")
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }

  public async init(): Promise<void> {
    this.fetchLockerData();
    this.fetchTodos();
    this.fetchModules();
  }

  private fetchLockerData(): void {
    const httpParams = new HttpParams({
      fromObject: {
        lockerIdentifier: this.lockerIdentifier,
        includeInternalIdentifier: true,
        includeStructure: true
      }
    });
    this.api.get<CBox_PublicSuccessResponse<CBox_AdminLockerData>>("backend/internal/locker", httpParams).subscribe((response) => {
      this.lockerData.set(response.data);
      this.dataFetched.update(fetched => {
        fetched["lockerData"] = true;
        return fetched;
      });

      this.createLockerExtras(response.data);
      this.createMeterItems(response.data);
      this.ready();
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
      this.dialogRef.close();
    });
  }

  private createLockerExtras(lockerData: CBox_AdminLockerData): void {
    const isOldHeartbeat = this.hasMinutesPassedSince(lockerData.heartbeat?.lastPingAt, 2);
    const memoryFreePercentage = this.calculatePercentageFromTotal(lockerData?.heartbeat?.data?.memory?.available || 0, lockerData?.heartbeat?.data?.memory?.total || 1);
    const storageFreePercentage = this.calculatePercentageFromTotal(lockerData?.heartbeat?.data?.storage?.available || 0, lockerData?.heartbeat?.data?.storage?.total || 1);
    this.lockerExtras.set({
      isOldHeartbeat,
      memoryFreePercentage,
      storageFreePercentage
    })
  }

  private createMeterItems(lockerData: CBox_AdminLockerData): void {
    const memoryMeterItems: MeterItem[] = [];
    const storageMeterItems: MeterItem[] = [];

    if (lockerData?.heartbeat?.data?.memory) {
      const { total, available } = lockerData.heartbeat.data.memory;
      memoryMeterItems.push({
        label: "Liber",
        value: this.calculatePercentageFromTotal(available, total),
        color: "var(--green-500)"
      });

      memoryMeterItems.push({
        label: "Ocupat",
        value: this.calculatePercentageFromTotal(total - available, total),
        color: "var(--red-500)"
      });
    }

    if (lockerData?.heartbeat?.data?.storage) {
      const { total, available } = lockerData.heartbeat.data.storage;
      storageMeterItems.push({
        label: "Liber",
        value: this.calculatePercentageFromTotal(available, total),
        color: "var(--green-500)"
      });

      storageMeterItems.push({
        label: "Ocupat",
        value: this.calculatePercentageFromTotal(total - available, total),
        color: "var(--red-500)"
      });
    }

    this.storageMeterItems.set(storageMeterItems);
    this.memoryMeterItems.set(memoryMeterItems);
  }

  private calculatePercentageFromTotal(value: number, total: number): number {
    if (total === 0) {
      return 0;
    }

    return (value / total) * 100;
  }

  hasMinutesPassedSince(date: Date | undefined, minutes: number): boolean {
    if (!date) {
      return false;
    }
    return hasMinutesPassedSince(date, minutes);
  }

  private fetchTodos(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_AdminGetTodoListDataItem>>("backend/internal/todo", this.getToDoSearchStructure()).subscribe((response) => {
      this.dataFetched.update(fetched => {
        fetched["todos"] = true;
        return fetched;
      });

      this.ready();
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
      this.dialogRef.close();
    });
  }

  private fetchModules(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_AdminModuleListItemDataResponse[]>>("backend/internal/modules/list?lockerIdentifier=" + this.lockerIdentifier).subscribe(async (response) => {
      this.modules.set(response.data);
      this.dataFetched.update(fetched => {
        fetched["modules"] = true;
        return fetched;
      });

      this.ready();

      for (const module of response.data) {
        this.modulesData.update(data => {
          data[module.id] = undefined;
          return data;
        });
      }

      for (const module of response.data) {
        this.fetchModuleData(module.id);
      }
    });
  }

  private fetchModuleData(moduleId: number): void {
    const oldData = this.modulesData()[moduleId];
    this.modulesData.update(data => {
      delete data[moduleId];
      return data;
    });

    this.api.get<CBox_PublicSuccessResponse<CBox_AdminLockerModuleDataResponse>>("backend/internal/modules?moduleId=" + moduleId).subscribe((response) => {
      this.modulesData.update(data => {
        data[moduleId] = response.data;
        return data;
      });
      this.moduleReady();
    }, () => {
      this.modulesData.update(data => {
        data[moduleId] = oldData;
        return data;
      });
    });
  }

  private createEquipmentsDatasource(): void {
    const equipments: ModuleEquipmentType[] = [];
    for (const moduleId of Object.keys(this.modulesData())) {
      const moduleData = this.modulesData()[+moduleId];
      if (moduleData?.equipments) {
        for (const equipment of moduleData.equipments) {
          equipments.push({
            ...equipment,
            module: {
              serial: moduleData.serial,
              id: +moduleId,
            },
          } as ModuleEquipmentType);
        }
      }
    }

    this.equipments.set(equipments);
    this.equipmentsReady.set(true);
  }

  private ready(): void {
    const isReady = Object.values(this.dataFetched()).every((value) => value === true);
    return this.loading.set(!isReady);
  }

  private moduleReady(): void {
    const isReady = Object.values(this.modulesData()).every((value) => value !== undefined);
    if (isReady) {
      this.createEquipmentsDatasource();
    }
  }

  private getToDoSearchStructure(): CBox_AdminGetTodoListDataParams {
    return Object.entries({
      "lockerIdentifiers[]": [this.lockerIdentifier],
      page: 1,
      pageSize: 25
    })
      .filter(([_, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }

        return value !== null && value !== undefined;
      })
      .reduce((acc, [key, value]) => {
        acc[key as keyof CBox_AdminGetTodoListDataParams] = value as CBox_AdminGetTodoListDataParams[keyof CBox_AdminGetTodoListDataParams];
        return acc;
      }, {} as any);
  }
}